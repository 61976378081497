import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "./HeaderMenu.css";
import logo from "../../assets/ependisis-logo-no-outline.png";
import { useEffect, useState } from "react";
import { UserInfo } from "../../redux/UserInfoReducer";
import { getUserInfo, useLogout } from "../../auth/AuthUtils";
import { Link } from "react-router-dom";
import { PersonCircle } from "react-bootstrap-icons";

interface HeaderMenuProps {
  showUserIcon?: boolean;
}

function HeaderMenu({ showUserIcon = true }: HeaderMenuProps) {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const handleLogout = useLogout();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = getUserInfo();
      if (user) {
        setUserInfo(user);
      }
    };
    fetchUserInfo();
  }, []);

  const handleAdminPage = () => {
    console.log("Accessing Admin Page");
  };

  return (
    <Navbar bg="transparent" expand="lg" className="header-navbar">
      <Container fluid className="header-container">
        {/* Left: Logo */}
        <Navbar.Brand href="/" className="header-logo">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>

        {/* Center: Navigation Menu */}
        <Nav className="header-nav">
          <Nav.Link href="/buyers" className="header-option">
            BUYERS
          </Nav.Link>
          <Nav.Link href="/sellers" className="header-option">
            SELLERS
          </Nav.Link>
          <Nav.Link href="/aboutUs" className="header-option">
            ABOUT
          </Nav.Link>
          <Nav.Link href="/services" className="header-option">
            SERVICES
          </Nav.Link>
        </Nav>

        {/* Right: Profile Icon */}
        {showUserIcon && (
          <Nav className="header-user-icon">
            <NavDropdown
              title={<PersonCircle size={30} style={{ cursor: "pointer" }} />}
              id="basic-nav-dropdown"
              className="dropdown-menu-right"
              onSelect={(eventKey: any) => {
                if (!userInfo && eventKey === "login") {
                  window.location.href = "/login";
                }
              }}
            >
              {userInfo === null && (
                <NavDropdown.Item eventKey="login">Log in</NavDropdown.Item>
              )}
              {userInfo !== null && (
                <>
                  <NavDropdown.Item disabled>
                    Hello, {userInfo.name}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  {userInfo?.roles === "Admin" && (
                    <NavDropdown.Item
                      as={Link}
                      to="/admin/dashboard/"
                      onClick={handleAdminPage}
                    >
                      Admin Page
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item onClick={handleLogout}>
                    Logout
                  </NavDropdown.Item>
                </>
              )}
            </NavDropdown>
          </Nav>
        )}
      </Container>
    </Navbar>
  );
}

export default HeaderMenu;
