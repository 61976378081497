import React from "react";
import { Col } from "react-bootstrap";
import { FaLinkedin, FaFacebook, FaEnvelope } from "react-icons/fa";

// Define the props interface
interface TeamMemberProps {
  image: string;
  name: string;
  role: string;
  email: string;
  socialLinks: { linkedin?: string; facebook?: string; email?: string };
}

const TeamMemberCard: React.FC<TeamMemberProps> = ({
  image,
  name,
  role,
  email,
  socialLinks,
}) => {
  return (
    <Col
      md={3}
      className="m-3 p-3"
      style={{
        border: "1px solid #DCE2F5",
        borderRadius: "10px",
        maxWidth: "270px",
        backgroundColor: "#EBEBEB",
        paddingBottom: "15px",
        textAlign: "left",
      }}
    >
      <img
        src={image}
        alt={name}
        style={{ width: "100%", borderRadius: "10px", marginBottom: "10px" }}
      />
      <h4
        style={{
          fontFamily: "Stix-Two",
          fontWeight: "bold",
          fontSize: "30px",
          color: "#272F53",
          marginBottom: 0,
        }}
      >
        {name}
      </h4>
      <p
        style={{
          fontSize: "25px",
          color: "#272F53",
          marginBottom: "10px",
          fontFamily: '"Helvetica-Neue-Light", sans-serif',
        }}
      >
        {role}
      </p>
      <hr
        style={{
          borderTop: "3px solid #3553A2",
          margin: "0 -15px",
        }}
      />
      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
        {socialLinks.email && (
          <a
            href={`mailto:${socialLinks.email}`}
            style={{ color: "#000000", fontSize: "25px" }}
          >
            <FaEnvelope />
          </a>
        )}
        {socialLinks.facebook && (
          <a
            href={socialLinks.facebook}
            style={{
              color: "#000000",
              fontSize: "25px",
              filter: "grayscale(100%)",
            }}
          >
            <FaFacebook />
          </a>
        )}
        {socialLinks.linkedin && (
          <a
            href={`https://${socialLinks.linkedin}`}
            style={{
              color: "#000000",
              fontSize: "25px",
              filter: "grayscale(100%)",
            }}
          >
            <FaLinkedin />
          </a>
        )}
      </div>

      <p
        style={{
          fontFamily: '"Helvetica-Neue-Light", sans-serif',
          fontSize: "18px",
          color: "#272F53",
          marginTop: "5px",
          marginBottom: 0,
        }}
      >
        {email}
      </p>
    </Col>
  );
};

export default TeamMemberCard;
