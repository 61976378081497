import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import backgroundImg from "../assets/login-bg.png";
import userImg from "../assets/user.png";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import Footer from "../components/Footer/Footer";
import { Login as EpendisisLogin } from "../apis/EpendisisApi";
import { LoginRequest } from "../interfaces/models/LoginRequest";
import secureLocalStorage from "react-secure-storage";
import { addUserInfo, addToken } from "../redux/UserInfoReducer";
import { LoginResponse } from "../interfaces/models/LoginResponse";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Fade } from "@mui/material";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import FooterMobile from "../components/Footer/FooterMobile";
import "../styles/Login.css";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [validated, setValidated] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    document.title = "Login";
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    try {
      await handleLogin();
    } finally {
      setValidated(true);
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    setValidated(true);
    if (!email || !password) {
      return;
    }

    try {
      const response = await EpendisisLogin(new LoginRequest(email, password));
      if (response.status == "200") {
        let loginRes: LoginResponse = response.data;
        secureLocalStorage.setItem("token", loginRes.token);
        secureLocalStorage.setItem("user", JSON.stringify(loginRes.user));
        dispatch(addToken(loginRes.token));
        dispatch(addUserInfo(JSON.stringify(loginRes.user)));

        const fromVerification = secureLocalStorage.getItem("fromVerification");
        if (fromVerification) {
          navigate(`/verifyEmail/${fromVerification}`);
        } else if (
          loginRes.user.status === "PendingVerification" ||
          loginRes.user.status === "PendingDetails" ||
          loginRes.user.status === "PendingDocuments" ||
          loginRes.user.status === "PendingApproval"
        ) {
          navigate("/onboarding");
        } else {
          if (loginRes.user.roles == "Admin") {
            navigate("/admin/dashboard");
          } else {
            navigate("/marketplace");
          }
        }
      } else {
        setErrorMsg(response.data.msg ?? "Something went wrong.");
        setAlertVisibility(true);
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <Container
      fluid
      className="wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "0",
      }}
    >
      <div
        className="login-page"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          flexDirection: "column",
          paddingBottom: "5%",
        }}
      >
        <Row className="d-none d-sm-flex justify-content-center">
          <HeaderMenu showUserIcon={false} />
        </Row>
        <Row className="d-block d-sm-none justify-content-center">
          <HeaderMenuMobile showUserIcon={false} />{" "}
        </Row>
        <Container style={{ flex: "1", marginTop: "3%" }}>
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div
                className="login-signup-div ml-10-mobile mr-10-mobile"
                style={{
                  backgroundColor: "white",
                  padding: "30px",
                  borderRadius: "10px",
                  paddingLeft: "15%",
                  paddingRight: "15%",
                }}
              >
                <div className="text-center mb-4">
                  <img
                    src={userImg}
                    alt="User Profile"
                    style={{ width: "80px", borderRadius: "50%" }}
                  />
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ marginBottom: "15px" }}
                  >
                    <Fade
                      in={alertVisibility}
                      timeout={{ enter: 1000, exit: 1000 }}
                    >
                      <Alert key="danger" variant="danger">
                        {errorMsg}
                      </Alert>
                    </Fade>
                    <Form.Control
                      className="text-center login-signup-input"
                      type="email"
                      placeholder="Your email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      style={{ marginBottom: "25px" }}
                    />
                    {/* <Form.Text
                      className="login-signup-comment text-muted text-center"
                      style={{ width: "100%", display: "grid" }}
                    >
                      This should be an email you can access after acquisition.
                    </Form.Text> */}
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicPassword"
                    style={{ marginBottom: "25px" }}
                  >
                    <Form.Control
                      className="text-center login-signup-input"
                      type="password"
                      placeholder="Your Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      minLength={8}
                      required
                    />
                    {/* <Form.Text
                      className="login-signup-comment text-muted text-center"
                      style={{ width: "100%", display: "grid" }}
                    >
                      Must include at least 8 characters
                    </Form.Text> */}
                    <Form.Text
                      className="text-muted text-center"
                      style={{ width: "100%", display: "grid" }}
                    >
                      <a
                        href="/forgetpassword"
                        className="forgot-password-link"
                      >
                        Forgot your password?
                      </a>
                    </Form.Text>

                    <Form.Control.Feedback type="invalid">
                      Password must be at least 8 characters.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="login-signup-button"
                    disabled={loading}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp; Loading...
                      </>
                    ) : (
                      "Sign in"
                    )}
                  </Button>
                </Form>
                <hr className="register-separator" />
                <Form.Text
                  className="text-muted text-center"
                  style={{ marginTop: "20px", display: "flow" }}
                >
                  Don't have an account?
                </Form.Text>

                <div className="register-links-container d-flex justify-content-center mt-3">
                  <Link
                    to="/buyers"
                    className="register-link"
                    style={{
                      textDecoration: "none",
                      marginRight: "15px",
                      marginTop: 0,
                      marginLeft: "15px",
                      color: "#2a345b",
                      fontWeight: "bold",
                    }}
                  >
                    Register as Buyer
                  </Link>
                  <Link
                    to="/sellers"
                    className="register-link"
                    style={{
                      textDecoration: "none",
                      marginLeft: "15px",
                      marginTop: 0,
                      marginRight: "15px",
                      color: "#2a345b",
                      fontWeight: "bold",
                    }}
                  >
                    Register as Seller
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container
        fluid
        className="justify-content-center"
        style={{
          backgroundColor: "white",
          width: "100%",
          bottom: "0",
        }}
      >
        <Row
          className="d-none d-sm-flex justify-content-center"
          style={{
            backgroundColor: "white",
          }}
        >
          <Footer />
        </Row>
        <Row
          className="d-block d-sm-none justify-content-center"
          style={{
            paddingLeft: "2%",
            paddingRight: "2%",
            paddingTop: "4%",
            backgroundColor: "white",
          }}
        >
          <FooterMobile />
        </Row>
      </Container>
    </Container>
  );
};

export default Login;
