import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InfoCard from "../components/InfoCard/InfoCard";
import { ShopWindow, People, Lightbulb } from "react-bootstrap-icons";

const iconStyle = { size: 40, color: "white" };

const infoCardsData = [
  {
    icon: <ShopWindow {...iconStyle} />,
    title: "Marketplace",
    description:
      "Ependisis.com is an online marketplace with a wide range of investors, including strategic buyers, venture capitalists, angel investors, private equity funds, family offices, and individuals seeking entrepreneurial opportunities.",
    backgroundColor: "#F5F6FA", // Light gray (default)
  },
  {
    icon: <People {...iconStyle} />,
    title: "Corporate Services",
    description:
      "Our commitment extends beyond providing a platform for investors. We offer specialized corporate advisory services designed to assist sellers in strategically positioning their companies.",
    backgroundColor: "white", // White background for the second card
  },
  {
    icon: <Lightbulb {...iconStyle} />,
    title: "Strategic Advisors",
    description:
      "We serve a crucial role as strategic advisors for both local and international institutional investors. Our team provides comprehensive insights and guidance to aid investors in making well-informed decisions.",
    backgroundColor: "#F5F6FA", // Light gray (default)
  },
];

const InfoCardsSection: React.FC = () => {
  return (
    <Container
      fluid
      style={{
        padding: "50px 0",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Container style={{ maxWidth: "1200px" }}> {/* Keeps layout consistent */}
        <Row className="justify-content-center">
          {infoCardsData.map((card, index) => (
            <Col key={index} xs={12} md={4} className="d-flex justify-content-center">
              <InfoCard
                icon={card.icon}
                title={card.title}
                description={card.description}
                backgroundColor={card.backgroundColor} // Pass background color
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default InfoCardsSection;
