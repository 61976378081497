import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import logo from "../../assets/logo-black-letters.png";

const Footer: React.FC = () => {
  return (
    <div className="footer-container">
      <Container>
        {/* Footer Main Section */}
        <Row className="footer-content">
          {/* Logo and Heading (50% Width) */}
          <Col xs={12} md={6} className="footer-logo-section">
            <img src={logo} alt="Ependisis Logo" className="footer-logo" />
            <h3 className="footer-heading">Don’t miss the next opportunity.</h3>
          </Col>

          {/* Contact Info (25% Width) */}
          <Col xs={12} md={3} className="footer-contact">
            <h5
              style={{
                color: "#2F3B68",
                fontFamily: "Helvetica-Neue, sans-serif",
                fontWeight: "bold",
              }}
            >
              Contact us
            </h5>
            <p style={{ fontStyle: "italic" }} className="footer-subheading">
              <a href="mailto:info@ependisis.com">info@ependisis.com</a>
            </p>
            <p>Gladstonos 31</p>
            <p>1095 Nicosia</p>
            <p>Cyprus</p>
          </Col>

          {/* Useful Links (25% Width) */}
          <Col
            xs={12}
            md={3}
            className="footer-links"
            style={{ marginBottom: "auto" }}
          >
            <h5
              style={{
                color: "#2F3B68",
                fontFamily: "Helvetica-Neue, sans-serif",
                fontWeight: "bold",
              }}
              className="footer-links-caption"
            >
              Useful Links
            </h5>
            <p>
              <a href="/privacypolicy">Privacy Policy</a>
            </p>
            <p>
              <a href="/cookiespolicy">Cookies Policy</a>
            </p>
            <p>
              <a href="/termsandconditions">Terms and Conditions</a>
            </p>
          </Col>
        </Row>

        {/* Copyright */}
        <Row className="footer-copyright-container">
          <Col xs={12} md={9} className="footer-spacer"></Col>
          <Col xs={12} md={3} className="footer-copyright">
            <p>Ependisis.com © 2025</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
