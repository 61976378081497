import React from "react";
import { Button } from "react-bootstrap";
import "./PricingTable.css";

const pricingData = [
  {
    title: "BASIC",
    features: [true, true, false, false, false, false, false, false, false],
  },
  {
    title: "PREMIUM",
    features: [true, true, true, true, true, true, true, true, false],
  },
  {
    title: "ENTERPRISE",
    features: [true, true, true, true, true, true, true, true, true],
  },
];

const featuresList = [
  "View public details of all listed opportunities",
  "Filter listings to find your ideal fit",
  "Browse quality private equity deals delivered straight to your inbox",
  "View in detail financial metrics",
  "Guided transaction workflow",
  "Draft and sign NDAs and LOIs",
  "Contact founders directly",
  "Transactions above 5M",
  "Users available (more than 1)",
];

const PricingTable: React.FC = () => {
  return (
    <div className="pricing-table">
      {/* Column Headers */}
      <div className="empty-header"></div>{" "}
      {/* Empty space without background */}
      {pricingData.map((plan, index) => (
        <div key={index} className="pricing-header">
          {plan.title}
        </div>
      ))}
      {/* Sign-up Buttons (Now Aligned Correctly) */}
      <div></div> {/* Empty space for alignment */}
      {pricingData.map((_, index) => (
        <div key={index} className="signup-button-container">
          <Button variant="light" className="signup-button">
            Sign up
          </Button>
        </div>
      ))}
      {/* Features and Checkmarks (Properly Aligned) */}
      {featuresList.map((feature, featureIdx) => (
        <React.Fragment key={featureIdx}>
          <div className="feature-item">{feature}</div>
          {pricingData.map((plan, planIdx) => (
            <div
              key={`${planIdx}-${featureIdx}`}
              className={`feature-check ${
                featureIdx === featuresList.length - 1 ? "last-row" : ""
              }`}
            >
              {plan.features[featureIdx] ? "✔" : "✖"}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PricingTable;
