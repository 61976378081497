import React from "react";
import PlanCardPropsNew from "../interfaces/components/PlanCard/PlanCardPropsNew";
import "./PlanCardContainer.css";
import { Col } from "react-bootstrap";
import { updatePackage, updateRole } from "../redux/RolePackageReducer";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import PlanCardNew from "../components/PlanCardNew/PlanCardNew";

function PlanCardNewContainer({
  data,
  roleSelected,
}: {
  data: PlanCardPropsNew[];
  roleSelected: string;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate function
  const navigateToContacts = (route: string) => {
    navigate(route);
    window.scrollTo(0, 0);
  };

  const handleButtonClick = (role: string | null, userPackage: string) => {
    secureLocalStorage.setItem("role", role ?? "");
    secureLocalStorage.setItem("package", userPackage);
    dispatch(updateRole(role));
    dispatch(updatePackage(userPackage));
    navigateToContacts("/signup");
  };

  return (
    <div className="plan-card-container-new">
      {data.map((item, index) => (
        <Col key={index} className="custom-width-desktop mobile-margin-bottom">
          <PlanCardNew
            key={index}
            {...item}
            buttonMethod={() =>
              handleButtonClick(roleSelected, (index + 1).toString())
            }
          />
        </Col>
      ))}
    </div>
  );
}

export default PlanCardNewContainer;
