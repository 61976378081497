import React from "react";
import { Col, Row } from "react-bootstrap";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import "./DealsShowcase.css"; // Import the new CSS file

const deals = [
  {
    title: "Boutique Hotel Chain",
    description:
      "Portfolio of boutique hotels located in Cyprus, aiming to enhance operational efficiencies and expand brand presence in the luxury market.",
    price: "€15 million",
    propertyArea: "Cyprus",
    cashFlow: "€20 million annually",
    tags: ["Private Equity", "Hospitality"],
  },
  {
    title: "Solar Park License",
    description:
      "A renewable energy company focused on developing solar parks, seeking growth capital to invest across new projects.",
    price: "€25 million",
    cashFlow: "Projected €5 million annually after expansion",
    tags: ["Growth", "Renewable Energy"],
  },
  {
    title: "Consumer Goods Manufacturer",
    description:
      "Leveraged buyout of a mid-sized consumer goods manufacturer, opportunity planning to streamline operations and increase market share through strategic acquisitions.",
    price: "€20 million",
    cashFlow: "€30 million annually",
    tags: ["Private Equity", "LBO", "Manufacturing", "Consumer Goods"],
  },
];

const DealsShowcase = () => {
  return (
    <Row className="deals-container">
      <Row className="deals-grid">
        {deals.map((deal, index) => (
          <Col key={index} className="deal-card">
            <div className="deal-content">
              {/* Title with Bookmark */}
              <div className="deal-header">
                <h4 className="deal-title">{deal.title}</h4>
                {index % 2 === 0 ? (
                  <BsBookmarkFill className="bookmark-icon" />
                ) : (
                  <BsBookmark className="bookmark-icon" />
                )}
              </div>

              <p className="deal-description">{deal.description}</p>
              <hr className="deal-divider" />

              {/* Investment Details */}
              <div className={`deal-details ${deal.propertyArea ? "three-columns" : "two-columns"}`}>
                <div>
                  <span className="detail-label">Price:</span>
                  <br />
                  <span className="detail-value">{deal.price}</span>
                </div>

                {deal.propertyArea && (
                  <div>
                    <span className="detail-label">Property Area:</span>
                    <br />
                    <span className="detail-value">{deal.propertyArea}</span>
                  </div>
                )}

                <div>
                  <span className="detail-label">Cash Flow:</span>
                  <br />
                  <span className="detail-value">{deal.cashFlow}</span>
                </div>
              </div>

              {/* Tags */}
              <div className="deal-tags">
                {deal.tags.map((tag, i) => (
                  <span key={i} className="tag">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Row>
  );
};

export default DealsShowcase;
