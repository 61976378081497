import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./redux/Store";
import ReactGA from "react-ga4";

ReactGA.initialize("G-Z2RVN0NKVS");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Send pageview with a custom path to Google Analytics
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
