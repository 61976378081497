import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CarouselVideo from "../assets/carousel.mp4";
import logo from "../assets/logo-isolated.png";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import SummaryCard from "../components/SummaryCard/SummaryCard";
import HomePropertyCard from "../components/HomePropertyCard/HomePropertyCard";
import Footer from "../components/Footer/Footer";
import ContactUs from "../components/ContactUs/ContactUs";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../styles/mobile/home.css";
import AccordionContainer from "../containers/AccordionContainer";
import FooterMobile from "../components/Footer/FooterMobile";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import StatsBanner from "../components/StatsBanner/StatsBanner";
import HomePropertySection from "../components/HomePropertySection/HomePropertySection";
import AccordionComponent from "../components/AccordionComponent/AccordionComponent";
import DealsShowcase from "../components/DealsShowCase/DealsShowcase";
import NewsletterSection from "../containers/NewsLetterSection";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/Store";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { isExpired } from "react-jwt";
import { WebDataItemResponse } from "../interfaces/models/WebDataItemResponse";
import { GetWebData } from "../apis/EpendisisApi";

function HomeMobile() {
  const navigate = useNavigate(); // Initialize navigate function
  const loggedInUser = useSelector((state: RootState) => state.user);
  let token: any = loggedInUser.token || secureLocalStorage.getItem("token");
  const isTokenInvalid = !token || isExpired(token);
  const [statsData, setStatsData] = useState<{ value: string; label: string }[]>([]);
  const handleNavigation = () => {
    if (isTokenInvalid) {
      navigate("/login"); // Redirect to login if not logged in
    } else {
      navigate("/marketplace"); // Otherwise, go to the marketplace
    }
  };

  const accordionData = [
    {
      title:
        "Does an Enterprise subscription guarantee I’ll find a business I like?",
      content:
        "Yes, absolutely. An Enterprise subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
    },
    {
      title: "Can I cancel a Premium or Enterprise subscription?",
      content:
        "Yes, you can cancel your subscription at any time from your account settings.",
    },
    {
      title:
        "I already have a Premium subscription. Can I upgrade to Enterprise?",
      content:
        "Yes, upgrading from Premium to Enterprise is possible through your account settings.",
    },
    {
      title: "How does priority support work with an Enterprise subscription?",
      content:
        "Enterprise subscribers receive 24/7 priority customer support via email and chat.",
    },
    {
      title:
        "Do I need an Enterprise subscription to obtain more than one opportunity?",
      content:
        "No, but an Enterprise subscription provides access to unlimited opportunities.",
    },
  ];
  const [transition, setTransition] = useState(false);

  const navigateToContacts = (route: string) => {
    navigate(route);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: WebDataItemResponse[] = await GetWebData(); // Call API
        const filteredData = data
          .filter((item) => item.SECTION === "State-Bar") // Filter by SECTION
          .map((item) => ({
            value: item.VALUE,
            label: item.TITLE,
          }));
        setStatsData(filteredData);
      } catch (error) {
        console.error("Error fetching stats data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const logoElement = document.getElementById("fade-out-logo");
    const fadeInRow = document.getElementById("fade-in-row");
    if (!transition && logoElement) {
      setTimeout(() => logoElement.classList.add("fade-out"), 10);
      setTimeout(() => setTransition(true), 1500);
    }
    if (transition && fadeInRow) {
      setTimeout(() => fadeInRow.classList.add("fade-in"), 500);
    }
  }, [transition]);

  const videoStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    minHeight: "100vh",
  };

  return (
    <>
      <Container
        fluid
        className="wrapper"
        style={{
          flexDirection: "column",
          minHeight: "100vh",
          height: "100%",
          padding: 0,
        }}
      >
        <Row>
          <Col
            xs={12}
            md={6}
            style={{ position: "relative", width: "100%", minHeight: "100vh" }}
          >
            <video className="videoTag" autoPlay loop muted style={videoStyle}>
              <source src={CarouselVideo} type="video/mp4" />
            </video>

            {/* Logo on Mobile and Desktop */}
            {!transition && (
              <Row
                className="initial-logo-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: 2,
                  width: "100%",
                  top: "40%",
                }}
              >
                <img
                  src={logo}
                  id="fade-out-logo"
                  style={{ height: "150px", width: "150px" }} // Adjusted for mobile
                  alt="Logo"
                />
              </Row>
            )}

            {/* Main Content */}
            <Row id="fade-in-row" style={{ position: "relative", zIndex: "2" }}>
              <Row className="d-none d-sm-flex justify-content-center">
                <HeaderMenu />
              </Row>
              <Row className="d-block d-sm-none justify-content-center">
                <HeaderMenuMobile />
              </Row>

              <Row
                style={{
                  width: "90%",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "20vh", // Adjust margin for mobile
                }}
              >
                {/* <Col className="text-wrap w-100">
                  <h3 className="gif-caption">BUSINESS DEALS</h3>
                </Col> */}
              </Row>

              <Row
                style={{
                  width: "85%",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "10px",
                }}
              >
                <Col>
                  <h4 className="gif-p">
                    Your Boutique Marketplace for
                    <br />
                    Private Equity and M&A Deals
                  </h4>
                </Col>
              </Row>

              <Row
                style={{
                  width: "85%",
                  textAlign: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "35px",
                  marginBottom: "100px", // Adjust margin for mobile
                }}
              >
                <Col>
                  <Button
                    className="redefined-button-white"
                    style={{ height: "50px", width: "250px" }}
                    onClick={handleNavigation}
                  >
                    Go to the Marketplace
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        {transition && (
          <>
            <div
              style={{
                position: "relative",
                width: "100%",
                marginTop: "-70px",
              }}
            >
              <StatsBanner stats={statsData} />
            </div>
            <Row
              style={{
                backgroundColor: "white",
                marginLeft: "4%",
                marginRight: "4%",
                marginTop: "4%",
              }}
            >
              <Col xs={12} className="platform-section">
                <h2 className="platform-title">What the platform does</h2>
              </Col>

              {/* Subcaption Section */}
              <Col xs={12} className="platform-text">
                <p>
                  A curated selection of deals tailored to your investment
                  preferences.
                </p>
              </Col>

              <Row
                style={{
                  marginTop: "2%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="justify-content-center w-100"
              >
                {/* Buyers Summary Card */}
                <Col
                  xs={12}
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "20px" }}
                >
                  <SummaryCard
                    title="For Buyers"
                    paragraphText="Discover the perfect deal to grow your portfolio or business and start due diligence effortlessly within minutes."
                    backgroundColor="#EBEBEB"
                    titleColor="#3553A2"
                    textColor="#444E71"
                  />
                </Col>

                {/* Marketplace Summary Card */}
                <Col
                  xs={12}
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "20px" }}
                >
                  <SummaryCard
                    title="Marketplace"
                    paragraphText="We understand the importance of starting the right dialogue to foster a mutually beneficial transaction."
                    backgroundColor="#0F1931"
                    titleColor="#E0E8FF"
                    textColor="#FFFFFF"
                  />
                </Col>

                {/* Sellers Summary Card */}
                <Col
                  xs={12}
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "20px" }}
                >
                  <SummaryCard
                    title="For Sellers"
                    paragraphText="Unlock the potential of crafting an impeccable listing with the guidance of our expert team."
                    backgroundColor="#B2C5F5"
                    titleColor="#3553A2"
                    textColor="#444E71"
                  />
                </Col>
                {/* Register Button */}
                <Col xs={12} className="register-container">
                  <button
                    className="register-button"
                    onClick={() => navigateToContacts("/login")} // Redirect to login
                  >
                    Register
                  </button>
                </Col>
              </Row>
            </Row>
            <Row>
              <HomePropertySection />
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                marginLeft: "4%",
                marginRight: "4%",
                marginTop: "4%",
              }}
            >
              <Col xs={12} className="platform-section">
                <h2 className="home-quote">
                  “Being successful in private equity requires an unrelenting
                  commitment to transforming vision into reality.”
                </h2>
              </Col>

              {/* Subcaption Section */}
              <Col xs={12} className="platform-text">
                <p className="home-quote-paragraph">- Mitt Romney</p>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "#0F1931",
                marginTop: "4%",
                paddingTop: "75px",
                paddingBottom: "75px",
              }}
            >
              <Col xs={12} className="platform-section">
                <h2
                  className="platform-title"
                  style={{ color: "white", fontSize: "45px" }}
                >
                  Example deals
                </h2>
              </Col>
              <Col xs={12}>
                <DealsShowcase />
              </Col>
              <Col xs={12} className="register-container">
                <button
                  className="register-button"
                  style={{ fontSize: "20px", maxWidth: "250px" }}
                  onClick={handleNavigation}
                >
                  Go to the Marketplace
                </button>
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "white",
                paddingTop: "75px",
                paddingBottom: "75px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Col xs={12} className="platform-section">
                <h2
                  className="platform-title"
                  style={{
                    color: "#0F1931",
                    fontSize: "45px",
                    maxWidth: "500px",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  Frequently Asked Questions
                </h2>
              </Col>
              <Col
                xs={12}
                className="platform-section"
                style={{ marginTop: "25px" }}
              >
                <AccordionComponent
                  items={accordionData}
                  className="enterprise-accordion"
                />
              </Col>
            </Row>
            <Row
              style={{
                backgroundColor: "#0F1931",
                paddingTop: "75px",
                paddingBottom: "75px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Col xs={12} className="platform-section">
                <h2
                  className="platform-title"
                  id="join-home-page"
                  style={{
                    color: "white",
                    fontSize: "45px",
                    maxWidth: "1000px",
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  Join reputable investors on Ependisis.com and access a diverse
                  range of opportunities.
                </h2>
              </Col>
              <Col style={{}}>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "170px",
                    marginTop: "35px",
                  }}
                >
                  <a
                    href="/marketplace"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      width: "auto",
                    }}
                  >
                    <Button
                      className="redefined-button-white"
                      style={{ height: "50px", width: "170px" }} // Adjusted for mobile
                      onClick={() => navigateToContacts("/login")} // Redirect to login
                    >
                      Join now
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
            {/* Subscribe Section */}
            <NewsletterSection />
          </>
        )}
        {/* {transition && (
          <Row
            style={{
              backgroundColor: "white",
              marginLeft: "4%",
              marginRight: "4%",
              marginTop: "7%",
            }}
          ></Row>
        )} */}
      </Container>
      <Row className="d-none d-sm-flex justify-content-center">
        <Footer />
      </Row>
      <Row className="d-block d-sm-none justify-content-center">
        <FooterMobile />
      </Row>
    </>
  );
}

export default HomeMobile;
