import { StringLiteral } from "typescript";

export class ProductsData {
  id: number;
  title: string;
  subTitle: string;
  categoryId: string;
  descriptionShort: string;
  descriptionLong: string;
  additionalInfo: string;
  terms: string;
  location: string;
  amount: string;
  icon: string;
  active = false;
  tags?: Tags[];
}

export class ProductsMandatory {
  title: Boolean;
  subTitle: Boolean;
  category: Boolean;
}

export class ProductsImagesMandatory {
  title: Boolean;
  type: Boolean;
  file: Boolean;
}

export class ProductsImages {
  id: number;
  title: string;
  type: string;
  url: string;
}

export class ProductsFeatures {
  id: number;
  feature: string;
  value: string;
  featureId: string;
}

export class Tags {
  id: number;
  name: string;
}

export class TagsDataMandatory {
  name: Boolean;
}
