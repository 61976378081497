import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import AboutUsBg from "../assets/aboutusbg.png";
import "./../styles/AboutUs.css";
import CircleIcon from "../components/CircleIcon/CircleIcon";
import { ShopWindow, People, Lightbulb } from "react-bootstrap-icons";
import ScrollingSections from "../components/ScrollingSections/ScrollingSections";
import logo from "../assets/logo-isolated.png";
import Footer from "../components/Footer/Footer";
import ContactUs from "../components/ContactUs/ContactUs";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import FooterMobile from "../components/Footer/FooterMobile";
import StatsBanner from "../components/StatsBanner/StatsBanner";
import InfoCardsSection from "../containers/InfoCardsSection";
import { useNavigate } from "react-router-dom";

function AboutUsNew() {
  const navigate = useNavigate(); // Initialize navigate function
  const navigateToContacts = (route: string) => {
    navigate(route);
    window.scrollTo(0, 0);
  };
  const statsData = [
    { value: "$100M +", label: "Transactions" },
    { value: "€1B +", label: "Investors AUM" },
    { value: "20 +", label: "Investors" },
    { value: "Free", label: "Consultation" },
  ];
  const titles = [
    { title: "Title 1", text: "Text 1" },
    { title: "Title 2", text: "Text 2" },
    { title: "Title 3", text: "Text 3" },
    // Add more title-text pairs as needed
  ];

  interface Section {
    title: string;
    text: string;
  }

  const sectionsAbout: Section[] = [
    {
      title: "Why Choose Ependisis",
      text: "Ependisis is dedicated to meeting the unique needs of each client through expert guidance and a broad range of services. Our extensive industry experience and commitment to quality ensure access to exceptional opportunities.",
    },
  ];

  const sectionsServices: Section[] = [
    {
      title: "Deal Origination and Aggregation",
      text: "Our team excels in finding and consolidating opportunities and pockets of value across various sectors. We focus on thorough evaluation and vetting processes, providing our clients with well-researched options that align with their objectives.",
    },
    {
      title: "Investor Matching and Facilitation",
      text: "Ependisis connects clients with opportunities that best match their criteria. We support clients throughout the transaction process, ensuring a smooth and efficient experience.",
    },
    {
      title: "Transparent Deal Insights and Analytics",
      text: "We prioritize transparency, offering comprehensive insights and straightforward analytics for each opportunity.",
    },
    {
      title: "Personalized Portfolio Diversification",
      text: "Ependisis aids clients in creating diversified portfolios that suit their individual corporate needs.",
    },
  ];
  return (
    <Container
      fluid
      className="wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Row>
        <Col
          md={6}
          style={{
            position: "relative",
            width: "100%",
            height: "600px",
            backgroundImage: `url(${AboutUsBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Row
            className="d-none d-sm-flex justify-content-center"
            style={{ backgroundColor: "transparent" }}
          >
            <HeaderMenu />
          </Row>
          <Row
            className="d-block d-sm-none justify-content-center"
            style={{
              backgroundColor: "#101324",
            }}
          >
            <HeaderMenuMobile />
          </Row>
          <Row
            className="d-block d-sm-none justify-content-center"
            style={{
              backgroundColor: "transparent",
            }}
          >
            <HeaderMenuMobile />
          </Row>
          <div id="about-us-main-caption">
            Empowering Investors, <br /> Connecting Opportunities
          </div>
        </Col>
      </Row>
      <Row
        style={{
          width: "85%",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10px",
        }}
      ></Row>
      <div
        style={{
          position: "relative",
          width: "100%",
          marginTop: "-70px",
        }}
      >
        <StatsBanner stats={statsData} />
      </div>
      <Row
        className="justify-content-md-center"
        style={{ backgroundColor: "white", paddingBottom: "3%" }}
      >
        <InfoCardsSection />
      </Row>

      <Row
        style={{
          backgroundColor: "white",
          marginLeft: "4%",
          marginRight: "4%",
        }}
      >
        <Col xs={12} className="platform-section">
          <h2 id="aboutus-quote">
            At Ependisis, we are revolutionizing the private equity landscape by
            creating a dynamic marketplace that empowers investors and connects
            buyers and sellers. Our mission is to provide a transparent and
            reliable platform that facilitates successful private equity
            transactions.
          </h2>
        </Col>
        <Col xs={12} className="register-container">
          <button
            className="register-button"
            style={{ fontSize: "20px", maxWidth: "250px" }}
            onClick={() => navigateToContacts("/login")} // Redirect to login
          >
            Register
          </button>
        </Col>
      </Row>

      <Row
        className="justify-content-md-center"
        style={{
          backgroundColor: "white",
          paddingTop: "3%",
          marginTop: "30px",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
        }}
      >
        <Row className="justify-content-md-center">
          <Col
            xs={12}
            md={3}
            className="section-scrolling-category-separator w-100-mobile p-0-mobile ml-8-mobile"
          >
            <div>
              <h3 className="section-scrolling-category">About</h3>
            </div>
          </Col>
          <Col
            xs={12}
            md={9}
            className="w-100-mobile p-0-mobile ml-8-mobile"
            style={{ paddingLeft: "2%", paddingRight: "8%" }}
          >
            <div>
              {/* <h1 id="about-sections-title">Why choose Ependisis</h1> */}
              <ScrollingSections sections={sectionsAbout} />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col
            xs={12}
            md={3}
            className="section-scrolling-category-separator w-100-mobile p-0-mobile ml-8-mobile"
          >
            {" "}
            <div>
              <h3 className="section-scrolling-category">Services</h3>
            </div>
          </Col>
          <Col
            xs={8}
            md={9}
            className="w-100-mobile p-0-mobile ml-8-mobile"
            style={{ paddingLeft: "2%", paddingRight: "8%" }}
          >
            <div className="app">
              {/* <h1 id="about-sections-title">Why choose Ependisis</h1> */}
              <ScrollingSections sections={sectionsServices} />
            </div>
          </Col>
        </Row>
      </Row>
      <Row
        className="justify-content-center"
        style={{ paddingTop: "5%", backgroundColor: "white" }}
      >
        <Col className="d-flex align-items-center justify-content-center">
          <div>
            <img
              src={logo}
              alt="Logo"
              style={{
                width: "50%",
                height: "auto",
                display: "block",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            />
          </div>
        </Col>
      </Row>
      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{
          paddingLeft: "1%",
          paddingRight: "1%",
          paddingTop: "1%",
          backgroundColor: "white",
        }}
      >
        <Footer />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
        style={{
          paddingTop: "4%",
          backgroundColor: "white",
        }}
      >
        <FooterMobile />
      </Row>
    </Container>
  );
}

export default AboutUsNew;
