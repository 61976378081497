import { Row, Col, Button, Alert } from "react-bootstrap";
import "./NewsLetterSection.css"; // Import the CSS file
import { SubscribeNewsletter } from "../apis/EpendisisApi";
import { SubscribeNewsletterRequest } from "../interfaces/models/SubscribeNewsletterRequest";
import { useState } from "react";
import ReactGA from "react-ga4";

const NewsletterSection = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<"success" | "error" | null>(null);
  const [message, setMessage] = useState("");

  const handleSubscribe = async () => {
    if (!email.trim()) {
      setStatus("error");
      setMessage("Please enter a valid email.");
      return;
    }

    try {
      const requestData: SubscribeNewsletterRequest = { email };
      await SubscribeNewsletter(requestData);
      setStatus("success");
      setMessage("Successfully subscribed!");
      setEmail(""); // Clear input

      // Send a custom event to Google analytics
      ReactGA.event({
        category: "Newsletter Subscribe",
        action: "User successfully subscribed",
        label: "NewsletterSubscribe", // optional
      });
    } catch (error) {
      setStatus("error");
      setMessage("Subscription failed. Please try again.");
    }
  };

  return (
    <Row
      className="newsletter-container"
      style={{ marginLeft: "auto", marginRight: "auto" }}
    >
      <Col xs={12}>
        <h2 className="newsletter-heading">
          Sign up for our exclusive newsletter.
        </h2>
      </Col>

      <Col xs={12} className="newsletter-input-container">
        <div className="newsletter-input-wrapper">
          <span className="newsletter-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              viewBox="0 0 16 16"
              opacity="0.8"
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
            </svg>
          </span>
          <input
            type="email"
            placeholder="Enter your email address"
            className="newsletter-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button className="subscribe-button-home" onClick={handleSubscribe}>
            Subscribe
          </Button>
        </div>
      </Col>

      {/* Success or Error Message */}
      {status && (
        <Col xs={12} className="mt-2">
          <Alert variant={status === "success" ? "success" : "danger"}>
            {message}
          </Alert>
        </Col>
      )}

      <Col xs={12} className="newsletter-privacy">
        <p>
          We care about your data. Read our{" "}
          <a href="/privacypolicy" className="privacy-link">
            privacy policy
          </a>
          .
        </p>
      </Col>
    </Row>
  );
};

export default NewsletterSection;
