import React from "react";
import "./FooterMobile.css"; // Import the CSS file

const FooterMobile = () => {
  return (
    <footer className="footer-mobile">
      <h3 className="footer-title">Don't miss the next opportunity.</h3>

      <div className="footer-links">
        <ul>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="/cookies-policy">Cookies Policy</a>
          </li>
          <li>
            <a href="/terms-conditions">Terms and Conditions</a>
          </li>
        </ul>
      </div>

      <div className="footer-contact">
        <h4>Contact Us</h4>
        <p className="footer-email">info@ependisis.com</p>
        <address>
          Gladstonos 31
          <br />
          1095 Nicosia, Cyprus
        </address>
      </div>

      <p className="footer-copyright">Ependisis.com © 2024</p>
    </footer>
  );
};

export default FooterMobile;
