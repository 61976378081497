import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import DataTableFilter from "../../components/DataTableFilter";
import {
  AddFeatures,
  getFeatureDropdown,
  GetProductsFeatures,
  DeleteProductsFeaturesById,
} from "../../api/Products.Api";
import DataTable, { TableColumn } from "react-data-table-component";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProductsFeatures } from "../../models/Products";
import SortIcon from "@mui/icons-material/ArrowDownward";
import Alert from "react-bootstrap/Alert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
interface ProductDetails {
  id: number;
}

const Features: React.FC<ProductDetails> = ({ id }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [featureList, setFeatureList] = useState([]);
  const [IsValidationError, setIsValidationError] = useState(false);
  const [IsnumerOnly, setIsnumerOnly] = useState(false);
  const handleShow = () => {
    setShow(true);
    setIsValidationError(false);
    setIsnumerOnly(false);
  };
  const [featureListGrid, setFeaturesListGrid] = useState<ProductsFeatures[]>([
    {
      id: 0,
      feature: "",
      value: "",
      featureId: "",
    },
  ]);
  const handleAddFeatures = () => {
    handleShow();
  };
  const [pending, setPending] = React.useState(true);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const loggedInUser = useSelector((state: RootState) => state.user);
  const [inputFields, setInputFields] = useState([
    { featureId: "", value: "" },
  ]);

  useEffect(() => {
    handleDropdown();
    loadFeaturesList();
  }, []);

  const loadFeaturesList = async () => {
    var features = await GetProductsFeatures(id, loggedInUser.token);
    if (features.length > 0) {
      setFeaturesListGrid(features);
    }
    setPending(false);
  };
  const handleDropdown = async () => {
    const response = await getFeatureDropdown(loggedInUser.token);
    setFeatureList(response);
  };
  const removeFeatureslistById = (feaList: ProductsFeatures[], id: number) =>
    setFeaturesListGrid(feaList.filter((feaList) => feaList.id !== id));

  const handleFeaturesDelete = async (featureID: number) => {
    const response = await DeleteProductsFeaturesById(
      featureID,
      loggedInUser.token
    );
    if (response.status == "200") {
      removeFeatureslistById(featureListGrid, featureID);
    }
  };

  const handleFormChange = (index: any, event: any) => {
    let data: any = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = { featureId: "", value: "" };

    setInputFields([...inputFields, newfield]);
  };
  const submit = (e: any) => {
    e.preventDefault();
    SaveFeatures();
  };

  const SaveFeatures = async () => {
    let isError = false;
    //validation error
    inputFields.forEach(function (value) {
      var feat = featureListGrid.filter(
        (feaList) => feaList.featureId == value.featureId
      );
      var duplicate = inputFields.filter(
        (feaList) => feaList.featureId == value.featureId
      );
      if (value.featureId == "3") {
        let price = Number(value.value);
        if (!price) {
          setIsnumerOnly(true);
          isError = true;
          return false;
        }
      }
      if (feat.length > 0) {
        setIsValidationError(true);
        isError = true;
        return false;
      }
      if (duplicate.length > 1) {
        setIsValidationError(true);
        isError = true;
        return false;
      }
    });
    if (isError) return;

    let features = await AddFeatures(id, inputFields, loggedInUser.token);
    if (features.status == "200") {
      if (features.data.affectedRows > 0) {
        handleClose();
        setIsValidationError(false);
        setIsnumerOnly(false);
        loadFeaturesList();
      }
    } else {
      //show error
    }
  };

  const removeFields = (index: any) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const handleDelete = (item: any) => {
    handleFeaturesDelete(item.id);
  };
  const columns: TableColumn<ProductsFeatures>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Feature",
      selector: (row) => row.feature,
      sortable: true,
    },
    {
      name: "value",
      selector: (row) => row.value,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="action-buttons">
          <IconButton color="error" onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  const filteredItems = featureListGrid.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <DataTableFilter
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        handleCreateNew={handleAddFeatures}
        addButtonName="Add Features"
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="App">
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Features</Modal.Title>
          {IsValidationError == true ? (
            <Alert key="danger" variant="danger">
              Duplicate Product Feature not allowed
            </Alert>
          ) : (
            ""
          )}
          {IsnumerOnly == true ? (
            <Alert key="danger" variant="danger">
              Only Numeric is allowed for Price
            </Alert>
          ) : (
            ""
          )}
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form onSubmit={submit}>
              {inputFields.map((input, index) => {
                return (
                  <div key={index}>
                    <Row>
                      <Col>
                        <Form.Select
                          name="featureId"
                          size="sm"
                          className="mb-3"
                          value={input.featureId}
                          onChange={(event) => handleFormChange(index, event)}
                        >
                          <option value=""></option>
                          {featureList?.map((feat: any) => (
                            <option value={feat.id}>{feat.title}</option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Control
                          name="value"
                          type="text"
                          className="mb-3"
                          size="sm"
                          placeholder="Value"
                          value={input.value}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                      </Col>
                      <Col>
                        <Button size="sm" onClick={() => removeFields(index)}>
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Form>
            <Row>
              <Col>
                <Button size="sm" variant="primary" onClick={addFields}>
                  Add More..
                </Button>{" "}
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" onClick={handleClose}>
            Close
          </Button>
          <Button size="sm" variant="primary" onClick={submit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {featureListGrid.length > 0 ? (
        <div style={{ padding: "0", width: "100%" }}>
          <DataTable
            title="Features/Metrics List"
            columns={columns}
            data={filteredItems}
            sortIcon={<SortIcon />}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            progressPending={pending}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Features;
