import React from "react";
import { Card } from "react-bootstrap";

interface InfoCardProps {
  icon: JSX.Element;
  title: string;
  description: string;
  backgroundColor?: string; // New optional prop
}

const InfoCard: React.FC<InfoCardProps> = ({ icon, title, description, backgroundColor }) => {
  return (
    <Card
      style={{
        textAlign: "center",
        borderRadius: "8px",
        padding: "20px",
        backgroundColor: backgroundColor || "#EBEBEB", // Default background color
        border: "none",
        width: "350px",
        height: "400px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Circular Icon Container */}
      <div
        style={{
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          backgroundColor: "#0D1B2A", // Dark blue background
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {icon}
      </div>

      <Card.Title style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#272F53", fontFamily: "Helvetica-Neue-Light, sans-serif" }}>
        {title}
      </Card.Title>
      <Card.Text style={{ fontSize: "1rem", color: "#444", marginTop: "10px", fontFamily: "Helvetica-Neue-Light, sans-serif", maxWidth: "300px" }}>
        {description}
      </Card.Text>
    </Card>
  );
};

export default InfoCard;
