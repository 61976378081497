import React, { useEffect, useState } from "react";
import { GetWebData, updateWebData } from "../../api/Webdesign.Api";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import { WebDataItem } from "../../models/WebData";
import WebSection from "./WebSection";

const WebdesignMainPage: React.FC = () => {
  const loggedInUser = useSelector((state: RootState) => state.user);
  const [sections, setSections] = useState<Record<string, WebDataItem[]>>({});

  const sectionContainsItem = (key: string, itemId: string): boolean => {
    return sections[key]?.some((item) => item.ID === itemId) || false;
  };

  const checkKeyExists = (key: string): boolean => {
    return key in sections; // Check if key exists
  };

  // const addOrUpdateSection = (key: string, items: WebDataItem[]) => {
  //   setSections((prevSections) => ({
  //     ...prevSections,
  //     [key]: items,
  //   }));
  // };

  const addItemToSection = (key: string, item: WebDataItem) => {
    setSections((prevSections) => ({
      ...prevSections,
      [key]: [...(prevSections[key] || []), item],
    }));
  };

  const updateSingleWebData = (
    sectionKey: string,
    itemId: string,
    updatedData: Partial<WebDataItem>
  ) => {
    setSections((prevSections) => ({
      ...prevSections,
      [sectionKey]: prevSections[sectionKey].map((item) =>
        item.CODE === itemId ? { ...item, ...updatedData } : item
      ),
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  function handleWebData(S: string, C: string, V: string, T: string): void {
    updateSingleWebData(S, C, { VALUE: V, TITLE: T });
  }

  // Function to save data via API call
  const handleSaveData = async () => {
    try {
      updateWebData(loggedInUser.token, Object.values(sections).flat());
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  // Simulate fetching data from an API
  const fetchData = async () => {
    try {
      const data = await GetWebData();
      data.forEach((item) => {
        if (!checkKeyExists(item.SECTION)) {
          addItemToSection(item.SECTION, item);
        }
      });
    } catch (error) {
      console.error("Error fetching web data:", error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        boxSizing: "border-box",
      }}
    >
      {/* Form Header */}
      <h1 style={{ textAlign: "left", marginBottom: "20px" }}>
        Website Content Configuration
      </h1>

      {Object.entries(sections).map(([sectionKey, items]) => (
        <div
          key={sectionKey}
          style={{
            marginBottom: "20px",
            border: "1px solid #ccc",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <h2
            key={sectionKey}
            style={{ fontSize: "20px", marginBottom: "20px" }}
          >
            {sectionKey}
          </h2>

          {items.map((data) => (
            // Call ChildComponent for each user in the list
            <WebSection key={data.CODE} data={data} onUpdate={handleWebData} />
          ))}
        </div>
      ))}

      {/* Save Button Container */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end", // Align to right
          alignItems: "center",
          marginTop: "40px",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <p style={{ marginRight: "20px", marginTop: "10px" }}>
          By clicking save the changes will apply to the website!
        </p>
        <button
          style={{
            padding: "12px 60px",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#fff",
            backgroundColor: "#007bff",
            border: "none",
            borderRadius: "6px",
            cursor: "pointer",
            transition: "background-color 0.3s",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "#0056b3")
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "#007bff")
          }
          onClick={handleSaveData}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default WebdesignMainPage;
