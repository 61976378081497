import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./HomePropertySection.css";
import PropertyCard from "../PropertyCard/PropertyCard";

// Define an interface for property card data
interface PropertyCard {
  title: string;
  description: string;
  variant: "dark" | "light"; // Determines the card styling
}

// Define the property card data dynamically
const propertyCards: PropertyCard[] = [
  {
    title: "Unparalleled Deal Access",
    description:
      "At Ependisis.com, our commitment to facilitating successful M&A transactions goes hand-in-hand with providing unparalleled deal access. Our platform ensures that investors have a broad spectrum of opportunities at their fingertips.",
    variant: "dark",
  },
  {
    title: "Cutting-edge Technology",
    description:
      "Driven by cutting-edge technology, Ependisis.com offers a seamless and user-friendly online marketplace experience. Our innovative platform leverages advanced technology.",
    variant: "light",
  },
  {
    title: "Transparency and Reliability",
    description:
      "Transparency and reliability are at the epicenter of Ependisis.com offers. Whether you are a seller looking to position your company strategically or an investor seeking opportunities, you can rely on our platform for transparent information to achieve your goals.",
    variant: "dark",
  },
  {
    title: "Investor Network",
    description:
      "Our platform connects investors with a dynamic and diverse member network. We believe in the power of a strong investor community and continuously strive to expand and diversify our network for the benefit of our users.",
    variant: "light",
  },
  {
    title: "Corporate Transaction Advice",
    description:
      "Our experienced team provides valuable insights and guidance to sellers navigating the complexities of strategic positioning. Whether you are entering negotiations or finalizing a deal, our advisory services contribute to the success of your M&A transactions.",
    variant: "dark",
  },
];

const HomePropertySection = () => {
  const navigate = useNavigate(); // React Router navigation
  const navigateToContacts = (route: string) => {
    navigate(route);
    window.scrollTo(0, 0);
  };
  const handleArrowClick = () => {
    navigateToContacts("/services");
  };

  return (
    <Container className="home-property-section">
      <Row className="property-cards">
        {/* Dynamically render property cards */}
        {propertyCards.map((card, index) => (
          <PropertyCard key={index} title={card.title} description={card.description} variant={card.variant} />
        ))}

        {/* Clickable Arrow with hover effect */}
        <Col xs={12} className="arrow-container" onClick={handleArrowClick}>
          <span className="read-more-text d-block d-sm-none">Read More</span>
          <ArrowForwardIosIcon className="arrow-icon" />
        </Col>
      </Row>
    </Container>
  );
};

export default HomePropertySection;
