import settings from "../../settings";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ProductDropdownResponse } from "../models/Dropdown";
import {
  ProductsData,
  ProductsFeatures,
  ProductsImages,
  Tags,
} from "../models/Products";

const EpendisisApi: AxiosInstance = axios.create({
  baseURL: settings.Functions.EpendisisApi,
});

export const getdropdown = async (
  token: string
): Promise<ProductDropdownResponse> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<ProductDropdownResponse> =
      await EpendisisApi.get("/api/products/getDropdown");
    return response.data;
  } catch (error: any) {
    return error?.response;
  }
};

export const getFeatureDropdown = async (token: string): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.get(
      "/api/products/getFeaturesDropdown"
    );
    return response.data;
  } catch (error: any) {
    return error?.response;
  }
};

export const getTags = async (token: string): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.get(
      "/api/products/getTags"
    );
    return response.data;
  } catch (error: any) {
    return error?.response;
  }
};

export const CreateProduct = async (
  data: ProductsData,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.post(
      "/api/products/createProducts",
      data
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const UpdateProduct = async (
  data: ProductsData,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.patch(
      "/api/products/updateProducts",
      data
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const ActivateProduct = async (
  prodId: number,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.get(
      "/api/products/activateProductStatus/" + prodId
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const GetAllProducts = async (
  token: string
): Promise<ProductsData[]> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<ProductsData[]> = await EpendisisApi.get(
      "/api/products/getProductsList"
    );
    return response.data;
  } catch (error: any) {
    return error?.response;
  }
};
export const GetProductsById = async (
  id: number,
  token: string
): Promise<ProductsData[]> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<ProductsData[]> = await EpendisisApi.get(
      "/api/products/getProductsById/" + id
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const AddFeatures = async (
  id: number,
  data: any,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.post(
      "/api/products/AddFeatures/" + id,
      data
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const UploadFile = async (
  productId: number,
  title: string,
  type: string,
  fileData: any,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const postData = new FormData();
    postData.append("file", fileData);

    const response: AxiosResponse<any> = await EpendisisApi.post(
      "/api/products/uploadSingle/" + productId,
      postData,
      {
        headers: {
          type: type,
          title: title,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const getImageslistByProdId = async (
  productId: number,
  token: string
): Promise<ProductsImages[]> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<ProductsImages[]> = await EpendisisApi.get(
      "/api/products/getImageslistByProdId/" + productId
    );
    return response.data;
  } catch (error: any) {
    return error?.response;
  }
};

export const deleteImagesById = async (
  id: number,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.delete(
      "/api/products/deletImageById/" + id
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const GetProductsFeatures = async (
  id: number,
  token: string
): Promise<ProductsFeatures[]> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<ProductsFeatures[]> = await EpendisisApi.get(
      "/api/products/getProductsFeaturesByProd/" + id
    );
    return response.data;
  } catch (error: any) {
    return error?.response;
  }
};

export const DeleteProductsFeaturesById = async (
  id: number,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.delete(
      "/api/products/deleteProductsFeaturesById/" + id
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const DeleteProductsById = async (
  prodID: number,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.delete(
      "/api/products/deleteProductsById/" + prodID
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const GetAllSellers = async (token: string): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.get(
      "/api/getAllSellers"
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const AssignProductToSeller = async (
  userId: any,
  prodId: number,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.get(
      "/api/products/assignProductToSeller/" + userId + "/" + prodId
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const GetProductSellerById = async (
  prodId: number,
  token: string
): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.get(
      "/api/products/getProductSellerById/" + prodId
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const GetTags = async (token: string): Promise<Tags[]> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<Tags[]> = await EpendisisApi.get(
      "/api/products/getTags"
    );
    return response.data;
  } catch (error: any) {
    return error?.response;
  }
};

export const CreateTags = async (token: string, data: Tags): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.post(
      "/api/products/createTags",
      data
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const UpdateTags = async (token: string, data: Tags): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.patch(
      "/api/products/updateTags",
      data
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};

export const DeleteTags = async (token: string, id: number): Promise<any> => {
  try {
    EpendisisApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response: AxiosResponse<any> = await EpendisisApi.delete(
      "/api/products/deleteTags/" + id
    );
    return response;
  } catch (error: any) {
    return error?.response;
  }
};
