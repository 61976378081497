import React, { useEffect, useRef, useState } from "react";
import "../styles/MarketplaceAsset.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Image,
  Toast,
  Modal,
} from "react-bootstrap";
import HeaderMenuMarketplace from "../components/HeaderMenuMarketplace/HeaderMenuMarketplace";
import BackButton from "../components/BackButton/BackButton";
import { AiFillHome } from "react-icons/ai";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Upload } from "react-bootstrap-icons";
import { Tabs, Tab } from "@mui/material";
import FooterMenu from "../components/FooterMenu/FooterMenu";
import AuthorizeWrapper from "../components/Auth/AuthorizeWrapper";
import { UserInfo } from "../redux/UserInfoReducer";
import { getUserInfo } from "../auth/AuthUtils";
import {
  CreateFavouriteProduct,
  DeleteFavouriteProduct,
  GetProductsDetailsById,
  RequestDocumentAccess,
  ContactAdminForProduct,
} from "../apis/EpendisisApi";
import { ProductDetails } from "../interfaces/models/ProductDetails";
import parse from "html-react-parser";
import { Feature } from "../interfaces/models/Feature";
import StatisticCard from "../components/StatisticCard/StatisticCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowRight, Building, Bank, BarChart } from "react-bootstrap-icons";
import { ProductIcons } from "../static/enums/ProductIcons";
import Footer from "../components/Footer/Footer";
import HeaderMenuMarketplaceMobile from "../components/HeaderMenuMarketplace/HeaderMenuMarketplaceMobile";
import FooterMobile from "../components/Footer/FooterMobile";

const MarketplaceAsset: React.FC = () => {
  const navigate = useNavigate();
  const [favouriteLoading, setFavouriteLoading] = useState(false);
  const [asset, setAsset] = useState<ProductDetails>();
  const [oneListFeatures, setOneListFeatures] = useState<Feature[]>([]);
  const [twoListFeatures, setTwoListFeatures] = useState<Feature[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("one");
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const userId = userInfo?.id?.toString();
  const location = useLocation();
  const summaryRef = useRef<HTMLDivElement | null>(null);
  const financialsRef = useRef<HTMLDivElement | null>(null);
  const metricsRef = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const fetchUserInfo = async () => {
    const user = getUserInfo();
    if (user) {
      setUserInfo(user);
    }
  };

  const handleContactUsClick = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await handleContactCustomer();
      setShowSuccessToast(true);
    } catch (error) {}
  };

  const fetchData = async () => {
    try {
      if (location) {
        let productId = location.pathname.slice(
          location.pathname.lastIndexOf("/") + 1,
          location.pathname.length
        );
        const data = await GetProductsDetailsById(
          productId || "",
          userId || ""
        );
        setAsset(data);
        const firstImageIndex = data?.images.findIndex(
          (image) => image.type === "Image"
        );
        setSelectedImageIndex(firstImageIndex);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getIconForProduct = (productIcon: string): JSX.Element => {
    switch (productIcon) {
      case ProductIcons.ArrowRight:
        return <ArrowRight size={30} color="#fff" />;
      case ProductIcons.Bank:
        return <Bank size={30} color="#fff" />;
      case ProductIcons.Building:
        return <Building size={30} color="#fff" />;
      case ProductIcons.BarChart:
        return <BarChart size={30} color="#fff" />;
      default:
        return <AiFillHome size={30} color="#fff" />;
    }
  };

  function getFileExtension(url: string): string {
    return url.split(".").pop() || ""; // Get the last element after splitting by '.', return empty string if undefined
  }

  useEffect(() => {
    document.title = "Marketplace";
    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (userId) {
      if (
        userInfo?.status === "PendingVerification" ||
        userInfo?.status === "PendingDetails" ||
        userInfo?.status === "PendingDocuments" ||
        userInfo?.status === "PendingApproval"
      ) {
        navigate("/onboarding");
        return;
      } else {
        fetchData();
      }
    }
  }, [userId]);

  useEffect(() => {
    if (asset?.features !== undefined) {
      let featuresArray = Array.isArray(asset?.features)
        ? asset?.features
        : [asset?.features];

      if (featuresArray.length > 0) {
        const midpoint = Math.floor(featuresArray.length / 2);

        const listOne = featuresArray.slice(0, midpoint);
        const listTwo = featuresArray.slice(midpoint);
        setOneListFeatures(listOne);
        setTwoListFeatures(listTwo);
      }
    }
  }, [asset?.features]);

  const handleRequestDocuments = async () => {
    try {
      let productId = location.pathname.slice(
        location.pathname.lastIndexOf("/") + 1,
        location.pathname.length
      );
      const result = await RequestDocumentAccess(productId, userId || "");
      const updatedAsset = new ProductDetails({
        ...asset,
        documentStatus: 0,
      });
      setAsset(updatedAsset);
    } catch (error) {}
  };

  const handleContactCustomer = async () => {
    try {
      let productId = location.pathname.slice(
        location.pathname.lastIndexOf("/") + 1,
        location.pathname.length
      );
      const result = await ContactAdminForProduct(productId, userId || "");
      const updatedAsset = new ProductDetails({
        ...asset,
        documentStatus: 0,
      });
      setAsset(updatedAsset);
    } catch (error) {}
  };

  const parsedDescriptionLong = parse(
    asset?.descriptionLong ? asset.descriptionLong : "-"
  );

  const parsedAdditionalInfo = parse(
    asset?.additionalInfo ? asset.additionalInfo : "-"
  );

  const parsedTerms = parse(asset?.terms ? asset.terms : "-");

  const handleFavoriteClick = async () => {
    try {
      setFavouriteLoading(true);
      if (userId !== undefined && asset?.id !== undefined) {
        const data = {
          customerId: parseInt(userId, 10),
          productId: asset?.id,
        };

        if (asset?.isFavourite === "true") {
          await DeleteFavouriteProduct(data);
        } else {
          await CreateFavouriteProduct(data);
        }
      }
    } catch (error) {
      console.error("Error toggling favorite status:", error);
    } finally {
      setAsset((prevAsset) => {
        if (!prevAsset) {
          return prevAsset; // If prevAsset is undefined, return as is
        }

        return {
          ...prevAsset,
          isFavourite: prevAsset?.isFavourite === "true" ? "false" : "true",
        };
      });
      setFavouriteLoading(false);
    }
  };

  const handleImageButtonClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  const handleChangeTab = (tabValue: string) => {
    setActiveTab(tabValue);

    switch (tabValue) {
      case "one":
        summaryRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "two":
        financialsRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "three":
        metricsRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <AuthorizeWrapper>
      <Container fluid>
        <Row
          className="d-none d-sm-flex justify-content-md-center pt-0-mobile pt-15"
          style={{
            backgroundColor: "#FFFFFF",
            paddingTop: "15px",
          }}
        >
          <HeaderMenuMarketplace />
        </Row>
        <Row
          className="d-block d-sm-none justify-content-md-center pt-0-mobile pt-15"
          style={{
            backgroundColor: "#FFFFFF",
            paddingTop: "15px",
          }}
        >
          <HeaderMenuMarketplaceMobile />
        </Row>
        <hr style={{ marginTop: "0", opacity: "0.5" }} />
        <Row className="justify-content-start">
          <BackButton />
        </Row>
        <Row
          className="justify-content-start align-items-center"
          style={{
            paddingBottom: "10px",
            marginTop: "10px",
            paddingRight: "11%",
            paddingLeft: "9%",
          }}
        >
          <Col
            xs={12}
            md={6}
            style={{
              display: "flex",
              alignItems: "center",
              width: "80%",
            }}
          >
            <div
              className="mr-10px-mobile mr-20"
              style={{
                width: "40px",
                height: "40px",
                backgroundColor: "#536BC1",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {getIconForProduct(asset?.icon ?? "")}
            </div>
            <h2
              className="marketplace-asset-title"
              style={{ fontWeight: "bold" }}
            >
              {asset?.title}
            </h2>
          </Col>
          <Col
            xs={12}
            md={6}
            className="justify-content-end align-items-center"
            style={{ display: "flex", width: "20%" }}
          >
            {/* Your custom buttons go here */}
            <Button
              variant="transparent"
              className="favorite-button-marketplace-asset"
              onClick={handleFavoriteClick}
              disabled={favouriteLoading}
            >
              {asset?.isFavourite === "true" ? (
                <BsHeartFill className="filled-heart-marketplace-asset" />
              ) : (
                <BsHeart className="unfilled-heart-marketplace-asset" />
              )}
            </Button>
            {/* <Button
              variant="transparent"
              className="favorite-button-marketplace-asset"
            >
              <Upload />
            </Button> */}
          </Col>
        </Row>
        <Row
          className="justify-content-start align-items-center"
          style={{
            paddingBottom: "15px",
            paddingRight: "11%",
            paddingLeft: "9%",
            height: "50%",
          }}
        >
          <div className="highlight-list">{asset?.subTitle}</div>
        </Row>
        <Row
          className="d-none d-sm-flex justify-content-start align-items-center"
          style={{
            paddingBottom: "15px",
            paddingRight: "11%",
            paddingLeft: "9%",
            opacity: "0.25",
          }}
        >
          <hr style={{ marginTop: "0" }} />
        </Row>
        <Row
          className="justify-content-start align-items-center"
          style={{
            paddingBottom: "15px",
            paddingRight: "11%",
            paddingLeft: "9%",
          }}
        >
          <Col xs={12} md={7} style={{ paddingBottom: "25px" }}>
            <img
              src={asset?.images[selectedImageIndex].url}
              alt="Your Alt Text"
              className="img-fluid rounded"
              onClick={handleShow} // Clicking the image opens the modal
              style={{ cursor: "pointer" }} // Changes the cursor to pointer for better UX
            />
          </Col>

          <Modal show={show} onHide={handleClose} centered>
            <Modal.Body>
              <img
                src={asset?.images[selectedImageIndex].url}
                alt="Your Alt Text"
                className="img-fluid rounded"
                style={{
                  maxWidth: "100%", // Image will not exceed the modal width
                  maxHeight: "90vh", // This ensures the image doesn’t get too tall
                }}
              />
            </Modal.Body>
          </Modal>

          <Col xs={12} md={5} style={{ marginBottom: "auto" }}>
            {asset?.location && (
              <Row style={{ marginBottom: "12px" }}>
                <div className="marketplace-asset-location-container">
                  <h2 className="marketplace-asset-location-tag"> Location</h2>
                  <div className="marketplace-asset-location">
                    <FaMapMarkerAlt style={{ marginRight: "5px" }} />
                    <span>{asset.location}</span>
                  </div>
                </div>
              </Row>
            )}
            <Row>
              <p className="marketplace-asset-location-description">
                {asset?.descriptionShort}
              </p>
            </Row>
            <Row style={{ paddingLeft: "12px" }}>{parsedAdditionalInfo}</Row>
            <Row
              className="mt-35 mt-10px-mobile"
              style={{
                height: "54px",
                paddingLeft: "12px",
              }}
            >
              <Button
                variant="primary"
                className="mt-auto h-100 blue-bg-white-col"
                onClick={handleContactUsClick}
              >
                <span className="marketplace-asset-button">
                  Contact Us For This Opportunity
                </span>
              </Button>
            </Row>
            {/* {asset.additionalInfo &&
              asset.additionalInfo.length > 0 && (
                <Row
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <h4 className="marketplace-asset-additional">
                    {" "}
                    Additional Information can be provided upon request
                  </h4>
                  <ul className="marketplace-asset-additional-list">
                    {asset.additionalInfo.map((info, index) => (
                      <li key={index}>
                        <span className="marketplace-asset-additional-list-item">
                          {info}
                        </span>
                      </li>
                    ))}
                  </ul>
                </Row>
              )} */}
            <Row
              className="mt-35 mt-10px-mobile justify-content-start align-items-center custom-scrollbar"
              style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <Col xs="auto" className="scroll-container">
                {asset?.images
                  .filter((image) => image.type === "Image")
                  .map((image, index) => (
                    <Button
                      key={index}
                      variant="light"
                      className={`image-button ${
                        index === selectedImageIndex ? "active" : ""
                      }`}
                      onClick={() => handleImageButtonClick(index)}
                    >
                      <Image
                        src={image?.url}
                        alt={`Image ${index + 1}`}
                        thumbnail
                      />
                    </Button>
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className="justify-content-start align-items-center"
          style={{
            paddingBottom: "10px",
            marginTop: "20px",
            paddingRight: "11%",
            paddingLeft: "9%",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => handleChangeTab(newValue)}
            aria-label="wrapped label tabs example"
          >
            <Tab
              className="marketplace-asset-tab"
              value="one"
              label="Summary"
            />
            <Tab
              className="marketplace-asset-tab"
              value="two"
              label="Financials"
            />
            <Tab
              className="marketplace-asset-tab"
              value="three"
              label="Metrics"
            />
          </Tabs>
        </Row>
        <Row
          ref={summaryRef}
          className="justify-content-start align-items-center"
          style={{
            paddingBottom: "20px",
            marginTop: "35px",
            paddingRight: "11%",
            paddingLeft: "9%",
          }}
        >
          <h3 className="marketplace-asset-tab-title">Summary</h3>
        </Row>
        <Row
          className="justify-content-start align-items-center"
          style={{
            paddingBottom: "10px",
            paddingRight: "11%",
            paddingLeft: "9%",
          }}
        >
          <p className="marketplace-asset-tab-subtitle">DESCRIPTION</p>
          <p className="marketplace-asset-tab-text">{parsedDescriptionLong}</p>
        </Row>
        <Row
          className="justify-content-start align-items-center"
          style={{
            paddingBottom: "10px",
            paddingRight: "11%",
            paddingLeft: "9%",
          }}
        >
          <p className="marketplace-asset-tab-subtitle">KEY ITEMS</p>
          <p> {parsedTerms}</p>
          {/* <p className="marketplace-asset-tab-text">
            {!asset?.keyItems ? (
              "-"
            ) : (
              <ul className="marketplace-asset-additional-list">
                {asset.keyItems.map((info, index) => (
                  <li key={index}>
                    <span className="marketplace-asset-tab-text">{info}</span>
                  </li>
                ))}
              </ul>
            )}
          </p> */}
        </Row>
        {oneListFeatures && (
          <Row
            className="justify-content-start align-items-center"
            style={{
              paddingBottom: "10px",
              paddingRight: "11%",
              paddingLeft: "9%",
            }}
          >
            {oneListFeatures.map((info, index) => (
              <Col
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                key={index}
                style={{
                  width: "15%",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <p className="marketplace-asset-tab-subtitle">
                  {info.featureTitle}
                </p>
                <p> {info.featureAmount}</p>
              </Col>
            ))}
          </Row>
        )}
        {twoListFeatures && (
          <Row
            className="justify-content-start align-items-center"
            style={{
              paddingBottom: "10px",
              paddingRight: "11%",
              paddingLeft: "9%",
            }}
          >
            {twoListFeatures.map((info, index) => (
              <Col
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                key={index}
                style={{
                  width: "15%",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                <p className="marketplace-asset-tab-subtitle">
                  {info.featureTitle}
                </p>
                <p> {info.featureAmount}</p>
              </Col>
            ))}
          </Row>
        )}
        <Row
          ref={financialsRef}
          className="justify-content-start align-items-center"
          style={{
            paddingBottom: "20px",
            marginTop: "35px",
            paddingRight: "11%",
            paddingLeft: "9%",
          }}
        >
          <h3 className="marketplace-asset-tab-title">Financials</h3>
        </Row>
        <Row
          className="justify-content-start align-items-center"
          style={{
            paddingBottom: "10px",
            paddingRight: "11%",
            paddingLeft: "9%",
          }}
        >
          {asset?.documentStatus === null && (
            <Row
              style={{
                height: "54px",
                paddingLeft: "12px",
              }}
            >
              <Button
                variant="primary"
                className="mt-auto h-100 blue-bg-white-col"
                style={{ width: "auto", marginLeft: "10px" }}
                onClick={handleRequestDocuments}
              >
                <span className="marketplace-asset-button">
                  Request Documents
                </span>
              </Button>
            </Row>
          )}
          {asset?.documentStatus === 0 && (
            <Row
              style={{
                height: "54px",
                paddingLeft: "12px",
              }}
            >
              <Button
                variant="primary"
                className="mt-auto h-100 blue-bg-white-col"
                style={{ width: "auto", marginLeft: "10px" }}
                disabled
              >
                <span className="marketplace-asset-button">
                  Request received, Pending Approval!
                </span>
              </Button>
            </Row>
          )}
          {asset?.documentStatus === 1 && (
            <Row
              style={{
                height: "54px",
                paddingLeft: "12px",
              }}
            >
              <ul>
                {asset?.images.map(
                  (image, index) =>
                    image.type === "Document" && (
                      <li key={index}>
                        <a
                          href={image.url}
                          download={image.title}
                          target="_blank"
                          style={{}}
                        >
                          Download '{image.title}.{getFileExtension(image.url)}'
                        </a>
                      </li>
                    )
                )}
              </ul>
            </Row>
          )}

          {asset?.documentStatus === 2 && (
            <Row
              style={{
                height: "54px",
                paddingLeft: "12px",
              }}
            >
              <Button
                variant="error"
                style={{ width: "auto", marginLeft: "10px" }}
                disabled
              >
                <span className="marketplace-asset-button">Access Denied!</span>
              </Button>
            </Row>
          )}
        </Row>
        <Row
          ref={metricsRef}
          className="justify-content-start align-items-center"
          style={{
            paddingBottom: "20px",
            marginTop: "35px",
            paddingRight: "11%",
            paddingLeft: "9%",
          }}
        >
          <h3 className="marketplace-asset-tab-title">Metrics</h3>
        </Row>
        {asset?.metrics && (
          <Row
            className="justify-content-start align-items-center"
            style={{
              paddingBottom: "20px",
              paddingRight: "11%",
              paddingLeft: "9%",
              marginBottom: "30px",
            }}
          >
            {asset?.metrics.map((info, index) => (
              <Col
                xs={6} // For mobile: Two cards per row
                md="auto" // For medium/desktop: Three cards per row (adjust as needed)
                className="scroll-container"
                style={{ padding: "10px" }} // Space between cards
                key={index}
              >
                <StatisticCard
                  icon={<FontAwesomeIcon icon={faChartSimple} />}
                  iconColor="white"
                  text={info.featureTitle}
                  statistic={info.featureAmount}
                  backgroundColor="#F8FAFF"
                />
              </Col>
            ))}
          </Row>
        )}
        <Toast
          show={showSuccessToast}
          onClose={() => setShowSuccessToast(false)}
          delay={3000}
          autohide
          style={{
            position: "fixed",
            bottom: 10,
            right: 10,
            background: "#28a745",
            color: "white",
            zIndex: "10",
          }}
        >
          <Toast.Body>Email Sent!</Toast.Body>
        </Toast>
        <Row
          className="d-none d-sm-flex justify-content-center"
          id="footer-row"
        >
          <Footer />
        </Row>
        <Row
          className="d-block d-sm-none justify-content-center"
          style={{ marginLeft: "2%", marginRight: "2%", marginTop: "4%" }}
        >
          <FooterMobile />
        </Row>
      </Container>
    </AuthorizeWrapper>
  );
};

export default MarketplaceAsset;
