import { Col, Container, Row } from "react-bootstrap";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import PlanCardContainerNew from "../containers/PlanCardContainerNew";
import "../styles/Home.css";
import QuestionAnswerTool from "../components/QuestionAnswerTool/QuestionAnswerTool";
import QuestionAnswerToolProps from "../interfaces/components/QuestionAnswerTool/QuestionAnswerToolProps";
import QuestionAnswerSingle from "../interfaces/components/QuestionAnswerTool/QuestionAnswerSingle";
import PackageTableNew from "../components/PackageTableNew/PackageTableNew";
import ContactUs from "../components/ContactUs/ContactUs";
import Footer from "../components/Footer/Footer";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import PlanCardNewContainer from "../containers/PlanCardContainerNew";
import FooterMobile from "../components/Footer/FooterMobile";
import AccordionComponent from "../components/AccordionComponent/AccordionComponent";
import PricingTable from "../components/PricingTable/PricingTable";
import PricingTableMobile from "../components/PricingTableMobile/PricingTableMobile";

const accordionData = [
  {
    title:
      "Does an Enterprise subscription guarantee I’ll find a business I like?",
    content:
      "Yes, absolutely. An Enterprise subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
  {
    title: "Can I cancel a Premium or Enterprise subscription?",
    content:
      "Yes, you can cancel your subscription at any time from your account settings.",
  },
  {
    title:
      "I already have a Premium subscription. Can I upgrade to Enterprise?",
    content:
      "Yes, upgrading from Premium to Enterprise is possible through your account settings.",
  },
  {
    title: "How does priority support work with an Enterprise subscription?",
    content:
      "Enterprise subscribers receive 24/7 priority customer support via email and chat.",
  },
  {
    title:
      "Do I need an Enterprise subscription to obtain more than one opportunity?",
    content:
      "No, but an Enterprise subscription provides access to unlimited opportunities.",
  },
];

const packages = [
  {
    name: "Basic",
    color: "#ffffff",
  },
  {
    name: "Premium",
    color: "#ffffff",
  },
  {
    name: "Enterprise",
    color: "#ffffff",
  },
];

const PlanCardData = [
  {
    title: "Basic",
    titleColor: "#FFFFFF",
    backgroundColor: "#0F1931",
    subtitle: "Browse the marketplace",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "Who is this plan for?",
        bullets: [
          "For aspiring investors who want to browse before starting their search",
        ],
      },
      {
        title: "What you get in this plan",
        bullets: [
          "Browse the marketplace to see what’s available",
          "View public listings of all startups including platinum and managed startups",
        ],
      },
    ],
  },
  {
    title: "Premium",
    titleColor: "#0F1931",
    backgroundColor: "#FFFFFF",
    subtitle: "Reach out to founders and owners",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "Who is this plan for?",
        bullets: [
          "For career entrepreneurs ready to find and acquire their next venture",
        ],
      },
      {
        title: "What you get in this plan",
        bullets: [
          "Reach out to founders to discuss acquisition",
          "Unlimited access to vetted business of up to EUR 250,000 TTM revenue",
          "Up to 1 user",
        ],
      },
    ],
  },
  {
    title: "Enterprise",
    titleColor: "#FFFFFF",
    backgroundColor: "#0F1931",
    subtitle: "Acquire the best business available",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "Who is this plan for?",
        bullets: [
          "For serial acquirers who want  the biggest and best deals guided by experts.",
        ],
      },
      {
        title: "What you get in this plan",
        bullets: [
          "Obtain the best business available",
          "Unlimited access to vetted opportunities of all sizes, including those we manage",
          "Up to 5 users",
        ],
      },
    ],
  },
];

const questionAnswer: QuestionAnswerSingle[] = [
  {
    question:
      "Does an Enterprise subscription guarantee I’ll find a business I like?",
    answer:
      "Answer 1. Yes, absolutely. An Enterprisee subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
  {
    question: "Can I cancel a Premium or Enterprise subscription?",
    answer:
      "Answer 2. Yes, absolutely. An Enterprisee subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
  {
    question:
      "I already have a Premium subscription. Can I upgrade to Enterprise?",
    answer:
      "Answer 3. Yes, absolutely. An Enterprisee subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
  {
    question: "How does priority support work with an Enterprise subscription?",
    answer:
      "Answer 4. Yes, absolutely. An Enterprisee subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
  {
    question:
      "Do I need an Enterprise subscription to obtain more than one opportunity?",
    answer:
      "Answer 5. Yes, absolutely. An Enterprisee subscription undoubtedly opens up a wealth of opportunities and benefits in the world of businesses. However, guaranteeing a perfect match between your preferences and an opportunity might be a bit ambitious. Let’s delve into what a Platinum subscription offers and what expectations to keep in mind.",
  },
];

function BuyersNew() {
  return (
    <Container
      fluid
      className="wrapper"
      style={{
        flexDirection: "column",
        minHeight: "100vh",
        height: "100%",
        padding: 0,
      }}
    >
      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{ backgroundColor: "#0F1931" }}
      >
        <HeaderMenu />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
        style={{
          backgroundColor: "#0F1931",
        }}
      >
        <HeaderMenuMobile />
      </Row>
      {/* Packages boxes      */}
      <Row
        style={{
          backgroundColor: "#0F1931",
          paddingBottom: "30px",
          paddingTop: "40px",
        }}
        className="justify-content-md-center"
      >
        <PlanCardNewContainer data={PlanCardData} roleSelected="2" />
        <Col xs={12} className="platform-section">
          <h2 className="platform-title" id="buyers-quote">
            Have an investment in mind that you think we’d be a great fit for
            it? We’d love to know what you’re thinking.
          </h2>
        </Col>
      </Row>
      <Row
        className="d-none d-sm-flex"
        style={{
          backgroundColor: "#DEE8FF",
          paddingTop: "75px",
          paddingBottom: "75px",
        }}
      >
        <PricingTable />
      </Row>
      <Row
        className="d-block d-sm-none"
        style={{
          backgroundColor: "#DEE8FF",
          paddingTop: "75px",
          paddingBottom: "75px",
        }}
      >
        <PricingTableMobile />
      </Row>
      <Row className="d-none d-sm-flex justify-content-center">
        <Footer />
      </Row>
      <Row className="d-block d-sm-none justify-content-center">
        <FooterMobile />
      </Row>
    </Container>
  );
}

export default BuyersNew;
