import React, { useEffect, useState } from "react";
import { RootState } from "../../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Spinner from "../../components/Spinner";
import Features from "./Features";
import ProductImages from "./ProductImages";
import Assignment from "./Assignment";
import { useSearchParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { ArrowRight, Building, Bank, BarChart } from "react-bootstrap-icons";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Button,
  Grid,
  FormLabel,
  SelectChangeEvent,
  Box,
  Typography,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
  AlertTitle,
  Fade,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import {
  getdropdown,
  CreateProduct,
  UpdateProduct,
  GetProductsById,
  ActivateProduct,
  GetTags,
} from "../../api/Products.Api";
import { ProductDropdownResponse } from "../../models/Dropdown";
import { ProductsData, ProductsMandatory, Tags } from "../../models/Products";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Products: React.FC = () => {
  const loggedInUser = useSelector((state: RootState) => state.user);
  const [dropdown, setDropdown] = useState<ProductDropdownResponse>();
  const [value, setValue] = React.useState(0);
  const [productIcon, setProductIcon] = React.useState<string>("1");

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [loading, setLoading] = React.useState(true);
  const [productID, setProductID] = useState<number>(0);
  const [alertVisibilitySuccess, setAlertVisibilitySuccess] = useState(false);
  const [alertVisibilityError, setAlertVisibilityError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    handleDropdown();
    loadTagsList();
    if (searchParams.size > 0) {
      let productId = searchParams.get("id");
      handleProductById(Number(productId));
    }
  }, []);
  const [formData, setFormData] = useState<ProductsData>({
    id: 0,
    title: "",
    subTitle: "",
    categoryId: "",
    descriptionShort: "",
    descriptionLong: "",
    additionalInfo: "",
    terms: "",
    location: "",
    amount: "",
    icon: "",
    active: false,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [tagList, setTagList] = useState<Tags[]>([]);

  const loadTagsList = async () => {
    var tags = await GetTags(loggedInUser.token);
    setTagList(tags);
  };

  const [isRequired, setIsRequried] = useState<ProductsMandatory>({
    title: false,
    subTitle: false,
    category: false,
  });
  let [searchParams, setSearchParams] = useSearchParams();

  const handleProductById = async (productId: number) => {
    setLoading(true);
    const response = await GetProductsById(productId, loggedInUser.token);
    if (response) {
      let product = response[0];
      setProductIcon(product.icon);
      setFormData({
        id: product.id,
        title: product.title,
        subTitle: product.subTitle,
        categoryId: product.categoryId,
        descriptionShort: product.descriptionShort,
        descriptionLong: product.title,
        additionalInfo: product.additionalInfo,
        terms: product.terms,
        location: product.location,
        amount: product.amount,
        icon: product.title,
        active: product.active,
      });
      setProductID(productId);
      setLoading(false);
    }
  };

  const handleDropdown = async () => {
    setLoading(true);
    const response = await getdropdown(loggedInUser.token);
    setDropdown(response);
    setLoading(false);
  };

  const handleProductIcon = (
    event: React.MouseEvent<HTMLElement>,
    newIcon: string
  ) => {
    setProductIcon(newIcon);
  };

  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name as string]: value as string, // Ensure value is treated as a string
    }));
  };

  const handleChangeDropdown = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name as string]: value,
    }));
  };

  const handleAmountBlur = () => {
    const formattedAmount = parseFloat(formData.amount).toFixed(2);

    setFormData((prevData) => ({
      ...prevData,
      amount: formattedAmount,
    }));
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // Replace non-numeric and non-decimal characters with an empty string
    const formattedAmount = value.replace(/[^0-9.]/g, "");

    setFormData((prevData) => ({
      ...prevData,
      amount: formattedAmount,
    }));
  };

  const handleCreateProduct = async () => {
    setLoading(true);
    var required = new ProductsMandatory();
    var isvalidation = true;
    if (formData.title === "") {
      required.title = true;
      isvalidation = false;
    }
    if (formData.subTitle === "") {
      required.subTitle = true;
      isvalidation = false;
    }
    if (formData.categoryId === "") {
      required.category = true;
      isvalidation = false;
    }
    if (!isvalidation) {
      setIsRequried(required);
      setLoading(false);
      return false;
    }
    formData.icon = productIcon;
    const response = await CreateProduct(formData, loggedInUser.token);
    if (response.status == "201") {
      setProductID(response.data.productId);
      if (response.data.productId > 0) {
        setAlertVisibilitySuccess(true);
      }
    } else {
      setErrorMsg(response.data.errors);
      setAlertVisibilityError(true);
    }
    setLoading(false);
  };

  const handleUpdateProduct = async () => {
    setLoading(true);
    var required = new ProductsMandatory();
    var isvalidation = true;
    if (formData.title === "") {
      required.title = true;
      isvalidation = false;
    }
    if (formData.subTitle === "") {
      required.subTitle = true;
      isvalidation = false;
    }
    if (formData.categoryId === "") {
      required.category = true;
      isvalidation = false;
    }
    if (!isvalidation) {
      setIsRequried(required);
      setLoading(false);
      return false;
    }
    formData.id = productID;
    formData.icon = productIcon;

    formData.tags = tagList.filter((tag) => tagName.includes(tag.name));
    const response = await UpdateProduct(formData, loggedInUser.token);
    if (response.status == "201") {
      if (response.data.affectedRows > 0) {
        setAlertVisibilitySuccess(true);
      }
    } else {
      setErrorMsg(response.data.errors);
      setAlertVisibilityError(true);
    }
    setLoading(false);
  };

  const handleActive = async () => {
    setLoading(true);
    const response = await ActivateProduct(productID, loggedInUser.token);
    if (response.status == "200") {
      formData.active = response.data.status;
      setAlertVisibilitySuccess(true);
    } else {
      setErrorMsg(response.data.errors);
      setAlertVisibilityError(true);
    }
    setLoading(false);
  };

  const [tagName, setTagName] = React.useState<string[]>([]);

  const handleChangeTag = (event: SelectChangeEvent<typeof tagName>) => {
    const {
      target: { value },
    } = event;
    setTagName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChangeTabs}
          aria-label="basic tabs example"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab
            label="Features"
            {...a11yProps(1)}
            disabled={productID > 0 ? false : true}
          />
          <Tab
            label="File Management"
            {...a11yProps(2)}
            disabled={productID > 0 ? false : true}
          />
          <Tab
            label="Assignment"
            {...a11yProps(3)}
            disabled={productID > 0 ? false : true}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {loading ? <Spinner /> : ""}
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              fullWidth
              required
              error={formData.title === "" && isRequired.title === true}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              label="Sub-Title"
              name="subTitle"
              value={formData.subTitle}
              onChange={handleChange}
              fullWidth
              required
              error={formData.subTitle === "" && isRequired.subTitle === true}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="category-lable">Category</InputLabel>
              <Select
                variant="outlined"
                name="categoryId"
                id="category"
                value={formData.categoryId}
                onChange={handleChangeDropdown}
                labelId="category-lable"
                label={"Category"}
                required
                error={
                  formData.categoryId === "" && isRequired.category === true
                }
              >
                {dropdown?.categoryList.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="location-lable">Location</InputLabel>
              <Select
                variant="outlined"
                labelId="location-lable"
                label={"Location"}
                name="location"
                id="location"
                required
                value={formData.location}
                onChange={handleChangeDropdown}
              >
                {dropdown?.locationList.map((loc) => (
                  <MenuItem key={loc.name} value={loc.name}>
                    {loc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={12}>
            <FormLabel>Short Description</FormLabel>
            <TextareaAutosize
              minRows={3}
              placeholder="This is a short description of the product"
              name="descriptionShort"
              value={formData.descriptionShort}
              onChange={handleChange}
              style={{ width: "100%" }}
              className="short-description-dashboard"
            />
          </Grid>

          <Grid item md={12}>
            <FormLabel>Long Description</FormLabel>
            <ReactQuill
              value={formData.descriptionLong}
              onChange={(value) =>
                setFormData((prevData) => ({
                  ...prevData,
                  descriptionLong: value,
                }))
              }
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <FormLabel>Terms</FormLabel>
            <ReactQuill
              value={formData.terms}
              onChange={(value) =>
                setFormData((prevData) => ({
                  ...prevData,
                  terms: value,
                }))
              }
            />
            {/* <TextareaAutosize
                            minRows={3}
                            placeholder="This is a terms of the product"
                            name="terms"
                            value={formData.terms}
                            onChange={handleChange}
                            style={{ width: "100%" }}
                            className="short-description-dashboard"
                        /> */}
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              label="Additional Info"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item md={6} style={{ display: "none" }}>
            <TextField
              label="Amount"
              name="amount"
              value={formData.amount}
              onChange={handleAmountChange}
              onBlur={handleAmountBlur}
              fullWidth
            />
          </Grid>

          <Grid item md={6}>
            {/* <label>Product Icon</label> */}
            <ToggleButtonGroup
              value={productIcon}
              exclusive
              onChange={handleProductIcon}
              aria-label="text alignment"
            >
              <ToggleButton value="1" aria-label="left aligned">
                <ArrowRight size={40} />
              </ToggleButton>
              <ToggleButton value="2" aria-label="centered">
                <Bank size={40} />
              </ToggleButton>
              <ToggleButton value="3" aria-label="right aligned">
                <Building size={40} />
              </ToggleButton>
              <ToggleButton value="4" aria-label="right aligned">
                <BarChart size={40} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <Divider sx={{ paddingTop: 3 }} />

        <Grid item sm={12} md={12}>
          <FormLabel>Tags: </FormLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={tagName}
            onChange={handleChangeTag}
            style={{ width: "500px" }}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {tagList.map((value) => (
              <MenuItem key={value.id} value={value.name}>
                <Checkbox checked={tagName.includes(value.name)} />
                <ListItemText primary={value.name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Box sx={{ paddingTop: 3 }}>
          <Grid container spacing={4} justifyContent="left">
            <Grid item md={2}>
              <Button
                style={{ height: "56px" }}
                variant="contained"
                onClick={handleActive}
                fullWidth
                color="warning"
              >
                {formData.active == true ? "Deactivate" : "Activate"}
              </Button>
            </Grid>
            <Grid item md={2}>
              {productID == 0 ? (
                <Button
                  style={{ height: "56px" }}
                  variant="contained"
                  onClick={handleCreateProduct}
                  fullWidth
                  color="success"
                >
                  Save
                </Button>
              ) : (
                <Button
                  style={{ height: "56px" }}
                  variant="contained"
                  onClick={handleUpdateProduct}
                  fullWidth
                  color="success"
                >
                  Update
                </Button>
              )}
            </Grid>
            <Grid item md={3}>
              <Fade
                in={alertVisibilitySuccess} //Write the needed condition here to make it appear
                timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                addEndListener={() => {
                  setTimeout(() => {
                    setAlertVisibilitySuccess(false);
                  }, 2000);
                }}
              >
                <Alert severity="success" variant="standard" className="alert">
                  <AlertTitle>Success</AlertTitle>
                </Alert>
              </Fade>
              <Fade
                in={alertVisibilityError} //Write the needed condition here to make it appear
                timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                addEndListener={() => {
                  setTimeout(() => {
                    setAlertVisibilityError(false);
                  }, 4000);
                }}
              >
                <Alert severity="error" variant="standard" className="alert">
                  <AlertTitle>{errorMsg}</AlertTitle>
                </Alert>
              </Fade>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Features id={productID} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ProductImages id={productID} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Assignment id={productID} />
      </CustomTabPanel>
    </Box>
  );
};

export default Products;
