import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import "./HeaderMenuMobile.css";
import logo from "../../assets/ependisis-logo-no-outline.png";
import { useEffect, useState } from "react";
import { UserInfo } from "../../redux/UserInfoReducer";
import { getUserInfo, useLogout } from "../../auth/AuthUtils";
import { Link } from "react-router-dom";
import { PersonCircle } from "react-bootstrap-icons";

interface HeaderMenuMobileProps {
  showUserIcon?: boolean; // Add a prop to control the visibility of the user icon
}

function HeaderMenuMobile({ showUserIcon = true }: HeaderMenuMobileProps) {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const handleLogout = useLogout();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = getUserInfo();
      if (user) {
        setUserInfo(user);
      }
    };
    fetchUserInfo();
  }, []);

  const handleAdminPage = () => {
    console.log("Accessing Admin Page");
  };

  return (
    <Navbar
      expand="lg"
      variant="dark"
      className="mobile-header-menu"
      style={{ paddingLeft: "15px", paddingRight: "15px" }}
    >
      <Container
        fluid
        className="d-flex justify-content-between align-items-center"
      >
        {/* Left: Expand icon */}
        <Navbar.Toggle aria-controls="mobile-navbar-nav" />

        {/* Middle: Logo */}
        <Navbar.Brand
          href="/"
          className="mx-auto d-flex justify-content-center"
        >
          <img src={logo} alt="Logo" className="mobile-header-logo" />
        </Navbar.Brand>

        {/* Right: User Icon */}
        {showUserIcon && (
          <Nav className="position-relative">
            <NavDropdown
              title={<PersonCircle size={30} style={{ cursor: "pointer" }} />}
              id="mobile-nav-dropdown"
              className="fixed-dropdown"
              align="end"
              onSelect={(eventKey: any) => {
                if (!userInfo && eventKey === "login") {
                  window.location.href = "/login";
                }
              }}
            >
              {userInfo === null && (
                <NavDropdown.Item eventKey="login">Log in</NavDropdown.Item>
              )}
              {userInfo !== null && (
                <>
                  <NavDropdown.Item disabled>
                    Hello, {userInfo.name}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  {userInfo?.roles === "Admin" && (
                    <NavDropdown.Item
                      as={Link}
                      to="/admin/dashboard/"
                      onClick={handleAdminPage}
                    >
                      Admin Page
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item onClick={handleLogout}>
                    Logout
                  </NavDropdown.Item>
                </>
              )}
            </NavDropdown>
          </Nav>
        )}
      </Container>

      {/* Collapsible menu */}
      <Navbar.Collapse id="mobile-navbar-nav" className="mobile-menu">
        <Nav className="me-auto mobile-nav-items">
          <Nav.Link href="/buyers" className="mobile-header-option">
            BUYERS
          </Nav.Link>
          <Nav.Link href="/aboutUs" className="mobile-header-option">
            ABOUT
          </Nav.Link>
          <Nav.Link href="/sellers" className="mobile-header-option">
            SELLERS
          </Nav.Link>
          <Nav.Link href="/services" className="mobile-header-option">
            SERVICES
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default HeaderMenuMobile;
