import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  GetTags,
  CreateTags,
  DeleteTags,
  UpdateTags,
} from "../../api/Products.Api";
import { Tags, TagsDataMandatory } from "../../models/Products";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTableFilter from "../../components/DataTableFilter";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextField,
  Button,
  Grid,
  IconButton,
  Box,
  Alert,
  AlertTitle,
  Fade,
} from "@mui/material";

const TagsList: React.FC = () => {
  const [isRequired, setIsRequried] = useState<TagsDataMandatory>({
    name: false,
  });
  const loggedInUser = useSelector((state: RootState) => state.user);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [tagsList, setTagsList] = useState<Tags[]>([
    {
      id: 0,
      name: "",
    },
  ]);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [formData, setFormData] = useState<Tags>({
    id: 0,
    name: "",
  });

  const [tagsID, setTagsID] = useState<number>(0);
  const [alertVisibility, setAlertVisibility] = useState(false);
  useEffect(() => {
    handletagsList();
  }, []);

  const handleAlertClickOpen = () => {
    setOpenAlert(true);
  };

  const handleAlertClickClose = () => {
    setOpenAlert(false);
  };

  const handletagsList = async () => {
    const tagsList = await GetTags(loggedInUser.token);
    if (tagsList.length > 0) {
      let response: Tags[] = tagsList;
      setTagsList(response);
      setPending(false);
    }
  };
  const handleEdit = (item: Tags) => {
    setTagsID(item.id);
    setFormData(item);
    setShow(true);
  };

  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name as string]: value as string, // Ensure value is treated as a string
    }));
  };

  const removeFeaturelistById = (featList: Tags[], id: number) =>
    setTagsList(featList.filter((featList) => featList.id !== id));

  const handleDelete = async (item: any) => {
    let deletefeatures = await DeleteTags(loggedInUser.token, item.id);
    if (deletefeatures.status == "200") {
      removeFeaturelistById(tagsList, item.id);
    } else {
      handleAlertClickOpen();
    }
  };
  const handleAddTags = () => {
    var setTextbox = new Tags();
    setTextbox.name = "";
    setFormData(setTextbox);
    setTagsID(0);
    setShow(true);
  };

  const handleCreate = async () => {
    var required = new TagsDataMandatory();
    var isvalidation = true;
    if (formData.name === "") {
      required.name = true;
      isvalidation = false;
    }
    if (!isvalidation) {
      setIsRequried(required);
      return false;
    }

    let features = await CreateTags(loggedInUser.token, formData);
    if (features.status == "201") {
      setAlertVisibility(true);
      handletagsList();
      var setTextbox = new Tags();
      setTextbox.name = "";
      setFormData(setTextbox);
      setShow(false);
    }
  };

  const handleUpdate = async () => {
    var required = new TagsDataMandatory();
    var isvalidation = true;
    if (formData.name === "") {
      required.name = true;
      isvalidation = false;
    }
    if (!isvalidation) {
      setIsRequried(required);
      return false;
    }

    let respond = await UpdateTags(loggedInUser.token, formData);
    if (respond.status == "201") {
      setAlertVisibility(true);
      handletagsList();
      setShow(false);
      var setTextbox = new Tags();
      setTextbox.name = "";
      setFormData(setTextbox);
      setShow(false);
    }
  };

  const columns: TableColumn<Tags>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="action-buttons">
          <IconButton color="primary" onClick={() => handleEdit(row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="error" onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  const filteredItems = tagsList.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <div>
        <DataTableFilter
          onFilter={(e: any) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          handleCreateNew={handleAddTags}
          addButtonName="Add Tag"
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <div style={{ padding: "0", width: "100%" }}>
      <Dialog
        open={openAlert}
        onClose={handleAlertClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The Tag cannot be deleted It is depend on the product. please delete
            the product first before deleting Tag.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClickClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Tags</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box>
            <Grid container spacing={3}>
              <Grid item sm={12} md={12}>
                <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  sx={{ width: 500 }}
                  required
                  error={formData.name === "" && isRequired.name === true}
                />
              </Grid>
              <Grid item sm={12} md={6}></Grid>
            </Grid>
            <Box sx={{ paddingTop: 2 }}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item md={3}>
                  <Fade
                    in={alertVisibility} //Write the needed condition here to make it appear
                    timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                    addEndListener={() => {
                      setTimeout(() => {
                        setAlertVisibility(false);
                      }, 4000);
                    }}
                  >
                    <Alert
                      severity="success"
                      variant="standard"
                      className="alert"
                    >
                      <AlertTitle>Success</AlertTitle>
                    </Alert>
                  </Fade>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleClose} sx={{ paddingRight: 3 }}>
            Close
          </Button>
          {tagsID === 0 ? (
            <Button variant="contained" onClick={handleCreate}>
              Save
            </Button>
          ) : (
            <Button variant="contained" onClick={handleUpdate}>
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <DataTable
        title="Tags List"
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        progressPending={pending}
      />
    </div>
  );
};

export default TagsList;
