import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateRole, updatePackage } from "../../redux/RolePackageReducer";
import secureLocalStorage from "react-secure-storage";
import "./PricingTableMobile.css";

const pricingData = [
  { title: "BASIC", features: [false, false, true, true, true, true, true, true, true] },
  { title: "PREMIUM", features: [true, true, true, true, true, true, true, true, true] },
  { title: "ENTERPRISE", features: [true, true, true, true, true, true, true, true, true] },
];

const featuresList = [
  "View public details of all listed opportunities",
  "Filter listings to find your ideal fit",
  "Browse quality private equity deals delivered straight to your inbox",
  "View in detail financial metrics",
  "Guided transaction workflow",
  "Draft and send LOIs and APAs in minutes",
  "Contact founders directly",
  "Transactions above 5M EUR",
  "Users available more than 1",
];

const PricingTableMobile: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState("BASIC");
  const roleSelected = "2";

  const handleButtonClick = (userPackage: string) => {
    secureLocalStorage.setItem("role", roleSelected);
    secureLocalStorage.setItem("package", userPackage);
    dispatch(updateRole(roleSelected));
    dispatch(updatePackage(userPackage));
    navigate("/signup");
  };

  const selectedPlanData = pricingData.find((plan) => plan.title === selectedPlan);

  return (
    <div className="pricing-table-mobile">
      <div className="pricing-buttons">
        {pricingData.map((plan) => (
          <button
            key={plan.title}
            className={`pricing-button ${selectedPlan === plan.title ? "selected" : ""}`}
            onClick={() => setSelectedPlan(plan.title)}
          >
            {plan.title}
          </button>
        ))}
      </div>

      <table className="pricing-table">
        <tbody>
          {featuresList.map((feature, index) => (
            <tr key={index}>
              <td className="feature-name">{feature}</td>
              <td className="feature-cell selected">
                {selectedPlanData?.features[index] ? "✔" : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PricingTableMobile;
