import { Col } from "react-bootstrap";

interface PropertyCardProps {
  title: string;
  description: string;
  variant: "dark" | "light";
}

const PropertyCard = ({ title, description, variant }: PropertyCardProps) => {
  return (
    <Col xs={12} className={`property-card ${variant}-card`}>
      <div>
        <h3 className="property-card-title">{title}</h3>
      </div>
      <div>
        <p className="property-card-paragraph">{description}</p>
      </div>
    </Col>
  );
};

export default PropertyCard;
