import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem, { listItemClasses } from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link, Outlet, NavLink, useLocation, Navigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import StarBorder from "@mui/icons-material/StarBorder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import secureLocalStorage from "react-secure-storage";
import AccessDenied from "../pages/AccessDenied";
import { addUserInfo, UserInfo, addToken } from "../../redux/UserInfoReducer";
import Login from "../../pages/legacy/Login";
import { isExpired } from "react-jwt";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { useLogout } from "../../auth/AuthUtils";
import InventoryIcon from "@mui/icons-material/Inventory";
import CategoryIcon from "@mui/icons-material/Category";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import TagIcon from "@mui/icons-material/LocalOffer";
import PersonIcon from "@mui/icons-material/Person";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import WebIcon from "@mui/icons-material/Web";
import "../css/admin.css";
import backgroundImage from "../assets/pattern.png";
export interface IAdminLayoutComponentProps {}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminLayout: React.FunctionComponent<IAdminLayoutComponentProps> = (
  props
) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [openMenu, setOpenMenu] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const { pathname } = useLocation();
  const handleLogout = useLogout();

  document.title = "Administrator";

  /*Authorization*/
  const loggedInUser = useSelector((state: RootState) => state.user);
  let token: any = loggedInUser.token;
  let userDetails: any = loggedInUser.userInfo;
  if (!token || !userDetails) {
    token = secureLocalStorage.getItem("token");
    userDetails = secureLocalStorage.getItem("user");
    if (!token || !userDetails) {
      return <Navigate to="/login" />;
    }
    dispatch(addToken(token.toString()));
    dispatch(addUserInfo(userDetails.toString()));
  }
  const isMyTokenExpired = isExpired(token);
  if (isMyTokenExpired) {
    secureLocalStorage.clear();
    return <Navigate to="/login" />;
  }

  //if not admin roles show access denied
  let obj: UserInfo = JSON.parse(userDetails);
  if (obj.roles !== "Admin") {
    return <AccessDenied />;
  }

  //logout menu
  const handleMenuToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    } else if (event.key === "Escape") {
      setOpenMenu(false);
    }
  }

  const handleMenuClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenMenu(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  function handleOpenSettings() {
    setOpenCollapse(!openCollapse);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const itemsList = [
    {
      text: "Dashboard",
      icon: <DashboardIcon style={{ fill: "orange" }} />,
      to: "/admin/dashboard", // <-- add link targets
    },
  ];
  const itemsListCustomers = [
    {
      text: "Customers",
      icon: <PersonIcon style={{ fill: "green" }} />,
      to: "/admin/customers", // <-- add link targets
    },
  ];

  const itemsListMail = [
    {
      text: "Mail List",
      icon: <MarkEmailReadIcon style={{ fill: "purple" }} />,
      to: "/admin/maillist", // <-- add link targets
    },
  ];

  const webDesign = [
    {
      text: "Web Design",
      icon: <WebIcon style={{ fill: "blue" }} />,
      to: "/admin/webdesign", // <-- add link targets
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{ backgroundColor: "rgb(21, 40, 60);" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component={"span"}>
              Admininistration
            </Typography>
            <div style={{ position: "absolute", top: "15%", right: "5%" }}>
              {/* <Button
               variant="contained"
                ref={anchorRef}
                id="composition-button"
                aria-controls={openMenu ? 'composition-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleMenuToggle}
                style={{ color: "white", fontSize: "20px" }}
              > */}
              <IconButton
                size="large"
                onClick={handleMenuToggle}
                style={{ color: "white" }}
                aria-controls={openMenu ? "composition-menu" : undefined}
                aria-expanded={openMenu ? "true" : undefined}
                aria-haspopup="true"
                ref={anchorRef}
                id="composition-button"
              >
                <SupervisedUserCircleIcon></SupervisedUserCircleIcon>
              </IconButton>
              {/* </Button> */}
              <Popper
                open={openMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleMenuClose}>
                        <MenuList
                          autoFocusItem={openMenu}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem>
                            <NavLink to="/">Back To Site</NavLink>
                          </MenuItem>
                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Box
            sx={{
              backgroundColor: "rgb(21, 40, 60);",
              height: "1200px",
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon style={{ color: "white" }} />
                ) : (
                  <ChevronLeftIcon style={{ color: "white" }} />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {itemsList.map((item, index) => {
                const { text, icon } = item;
                return (
                  <ListItem
                    selected={item.to === pathname}
                    component={Link}
                    to={item.to}
                    key={text}
                  >
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={text} style={{ color: "white" }} />
                  </ListItem>
                );
              })}
            </List>
            <Divider />
            <List>
              {itemsListCustomers.map((item, index) => {
                const { text, icon } = item;
                return (
                  <ListItem
                    selected={item.to === pathname}
                    component={Link}
                    to={item.to}
                    key={text}
                  >
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={text} style={{ color: "white" }} />
                  </ListItem>
                );
              })}
            </List>
            <Divider />
            <List>
              {itemsListMail.map((item, index) => {
                const { text, icon } = item;
                return (
                  <ListItem
                    selected={item.to === pathname}
                    component={Link}
                    to={item.to}
                    key={text}
                  >
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={text} style={{ color: "white" }} />
                  </ListItem>
                );
              })}
            </List>
            <Divider />

            <ListItem onClick={handleOpenSettings}>
              <ListItemIcon>
                <InventoryIcon style={{ fill: "red" }} />
              </ListItemIcon>
              <ListItemText primary="Investment" style={{ color: "white" }} />
              {openCollapse ? (
                <ExpandLess style={{ color: "white" }} />
              ) : (
                <ExpandMore style={{ color: "white" }} />
              )}
            </ListItem>
            <Collapse
              in={openCollapse}
              timeout="auto"
              unmountOnExit
              style={{ color: "white" }}
            >
              <List component="div" disablePadding>
                <ListItem
                  sx={{ pl: 4 }}
                  selected={"/admin/productslist" === pathname}
                  component={Link}
                  to="/admin/productslist"
                >
                  <ListItemIcon>
                    <ShowChartIcon style={{ fill: "red" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Investment List"
                    style={{ color: "white" }}
                  />
                </ListItem>
                <ListItem
                  sx={{ pl: 4 }}
                  selected={"/admin/featureslist" === pathname}
                  component={Link}
                  to="/admin/featureslist"
                >
                  <ListItemIcon>
                    <CategoryIcon style={{ fill: "red" }} />
                  </ListItemIcon>
                  <ListItemText primary="Features" style={{ color: "white" }} />
                </ListItem>
                <ListItem
                  sx={{ pl: 4 }}
                  selected={"/admin/CategoriesList" === pathname}
                  component={Link}
                  to="/admin/CategoriesList"
                >
                  <ListItemIcon>
                    <FeaturedPlayListIcon style={{ fill: "red" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Categories"
                    style={{ color: "white" }}
                  />
                </ListItem>
                <ListItem
                  sx={{ pl: 4 }}
                  selected={"/admin/TagsList" === pathname}
                  component={Link}
                  to="/admin/TagsList"
                >
                  <ListItemIcon>
                    <TagIcon style={{ fill: "red" }} />
                  </ListItemIcon>
                  <ListItemText primary="Tags" style={{ color: "white" }} />
                </ListItem>
              </List>
            </Collapse>

            <List>
              {webDesign.map((item, index) => {
                const { text, icon } = item;
                return (
                  <ListItem
                    selected={item.to === pathname}
                    component={Link}
                    to={item.to}
                    key={text}
                  >
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={text} style={{ color: "white" }} />
                  </ListItem>
                );
              })}
            </List>
            <Divider />
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
export default AdminLayout;
