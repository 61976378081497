import React, { useEffect, useState, useMemo } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { GetAllProducts, DeleteProductsById } from "../../api/Products.Api";
import { ProductsData } from "../../models/Products";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import DataTableFilter from "../../components/DataTableFilter";
import { RootState } from "../../../redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";

const ProductsList: React.FC = () => {
  const navigate = useNavigate();
  const [pending, setPending] = React.useState(true);
  const loggedInUser = useSelector((state: RootState) => state.user);
  const [productsList, setProductsList] = useState<ProductsData[]>([
    {
      id: 0,
      title: "",
      subTitle: "",
      categoryId: "",
      descriptionShort: "",
      descriptionLong: "",
      additionalInfo: "",
      terms: "",
      location: "",
      amount: "",
      icon: "",
      active: true,
    },
  ]);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  useEffect(() => {
    handleProductList();
  }, []);

  const handleProductList = async () => {
    const productsList = await GetAllProducts(loggedInUser.token);
    setProductsList(productsList);
    setPending(false);
  };
  const handleEdit = (item: ProductsData) => {
    let url = "/admin/products?id=" + item.id;
    navigate(url);
  };

  const removeProductById = (prodList: ProductsData[], id: number) =>
    setProductsList(prodList.filter((prodList) => prodList.id !== id));

  const handleDelete = async (item: any) => {
    const response = await DeleteProductsById(item.id, loggedInUser.token);
    if (response.status == "200") {
      removeProductById(productsList, item.id);
    }
  };

  const colorCell = (value: string) => {
    if (value === "Not Active") {
      return <div style={{ color: "orange" }}>{value}</div>;
    } else if (value === "Active") {
      return <div style={{ color: "#06bf06" }}>{value}</div>;
    } else {
      return <div>{value}</div>;
    }
  };

  const columns: TableColumn<ProductsData>[] = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "SubTitle",
      selector: (row) => row.subTitle,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.categoryId,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => (row.active == true ? "Active" : "Not Active"),
      cell: (row) => colorCell(row.active == true ? "Active" : "Not Active"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="action-buttons">
          <IconButton color="primary" onClick={() => handleEdit(row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="error" onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];
  const handleNewProducts = () => {
    let url = "/admin/products";
    navigate(url);
    window.location.reload();
  };

  const filteredItems = productsList.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <DataTableFilter
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        handleCreateNew={handleNewProducts}
        addButtonName="New Investment"
      />
    );
  }, [filterText, resetPaginationToggle]);
  return (
    <div style={{ padding: "0", width: "100%" }}>
      <DataTable
        title="Investments List"
        columns={columns}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        progressPending={pending}
      />
    </div>
  );
};

export default ProductsList;
