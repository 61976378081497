import { Col, Container, Row } from "react-bootstrap";
import ContactUs from "../components/ContactUs/ContactUs";
import PlanCardContainerNew from "../containers/PlanCardContainerNew";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import Footer from "../components/Footer/Footer";
import QuestionAnswerSingle from "../interfaces/components/QuestionAnswerTool/QuestionAnswerSingle";
import "../styles/Sellers.css";
import SellerIdea from "../components/SellerIdea/SellerIdea";
import FooterMobile from "../components/Footer/FooterMobile";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import AccordionComponent from "../components/AccordionComponent/AccordionComponent";
import PropertyCard from "../components/PropertyCard/PropertyCard";

interface ServiceCard {
  title: string;
  description: string;
  variant: "dark" | "light"; // Enforce strict types
}

const serviceCards: ServiceCard[] = [
  {
    title: "Valuation and Market Positioning",
    description:
      "Our expert team transforms complex financial landscapes into precise, compelling valuations that highlight your company's unique market potential.",
    variant: "dark",
  },
  {
    title: "Investor Matching and Facilitation",
    description:
      "We craft sophisticated investor outreach strategies that strategically connect your opportunity with the most aligned global capital partners.",
    variant: "light",
  },
  {
    title: "Transparent Deal Insights and Analytics",
    description:
      "Our seasoned negotiators architect deal structures that maximize value, balancing strategic objectives with financial optimization.",
    variant: "dark",
  },
  {
    title: "Due Diligence",
    description:
      "We meticulously manage the entire documentation and compliance process, ensuring transparency, regulatory adherence, and seamless transaction execution.",
    variant: "light",
  },
];

const questionAnswer = [
  {
    title: "Is there a listing fee?",
    content:
      "No, you list for free and then only pay a flat 4% closing fee when you get an opportunity. If you don’t sell your business (we’re confident you will), you don’t pay a thing.",
  },
  {
    title: "Do I pay a fee if I can't sell my opportunity?",
    content:
      " No, you list for free and then only pay a flat 4% closing fee when you get an opportunity. If you don’t sell your business (we’re confident you will), you don’t pay a thing.",
  },
  {
    title: "Who pays for escrow?",
    content:
      " No, you list for free and then only pay a flat 4% closing fee when you get an opportunity. If you don’t sell your business (we’re confident you will), you don’t pay a thing.",
  },
  {
    title: "Do I need professional services when selling with Ependisis.com?",
    content:
      "No, you list for free and then only pay a flat 4% closing fee when you get an opportunity. If you don’t sell your business (we’re confident you will), you don’t pay a thing.",
  },
  {
    title: "How does Ependisis.com help me sell?",
    content:
      " No, you list for free and then only pay a flat 4% closing fee when you get an opportunity. If you don’t sell your business (we’re confident you will), you don’t pay a thing.",
  },
];

const sellersIdeas = [
  {
    title: "Valuation and Market Positioning",
    ideas: [""],
  },
  {
    title: "Investor Matching and Facilitation",
    ideas: [""],
  },
  {
    title: "Transparent deal insights and analytics",
    ideas: [""],
  },
  {
    title: "Personalized Portfolio Diversification",
    ideas: [""],
  },
];

const PlanCardData = [
  {
    title: "Flat Listings",
    titleColor: "#FFFFFF",
    backgroundColor: "#0F1931",
    subtitle: "Listing guidance",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "What you need to know:",
        bullets: [
          "Legal and Compliance Due Diligence",
          "Listing cost on website",
          "Coordinating meetings as an observer",
        ],
      },
    ],
  },
  {
    title: "Managed Services",
    titleColor: "#0F1931",
    backgroundColor: "#FFFFFF",
    subtitle: "Acquisition & Process Management",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "What you need to know:",
        bullets: [
          "Legal and Marketing help",
          "Vetted matchmaking",
          "Data room preparation",
          "Due Diligence and escrow for offers received",
          "Coordination of roadshows and meetings",
        ],
      },
    ],
  },
  {
    title: "Transaction Fee",
    titleColor: "#FFFFFF",
    backgroundColor: "#0F1931",
    subtitle: "Assistance along the process",
    buttonText: "Sign up",
    bulletList: [
      {
        title: "What you need to know:",
        bullets: [
          "The transaction fee applies to both scenarios regardless of listing plan chosen",
          "Ependisis.com retains the option, by exception, to convert part of the 5% into a hybrid version between cash and equity",
        ],
      },
    ],
  },
];

function SellersNew() {
  return (
    <Container
      fluid
      className="wrapper"
      style={{
        flexDirection: "column",
        minHeight: "100vh",
        height: "100%",
        padding: 0,
      }}
    >
      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{ backgroundColor: "#0F1931" }}
      >
        <HeaderMenu />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
        style={{
          backgroundColor: "#0F1931",
        }}
      >
        <HeaderMenuMobile />
      </Row>
      {/* Packages boxes      */}
      <Row
        style={{
          backgroundColor: "#0F1931",
          paddingBottom: "30px",
          paddingTop: "30px",
        }}
        className="justify-content-md-center"
      >
        <PlanCardContainerNew data={PlanCardData} roleSelected="3" />
        <Col xs={12} className="platform-section">
          <h2 className="platform-title" id="sellers-quote">
            Have an investment in mind that you think we’d be a great fit for
            it? We’d love to know what you’re thinking.
          </h2>
        </Col>
      </Row>
      {/*
      <Row
        style={{
          backgroundColor: "white",
          paddingTop: "75px",
          paddingBottom: "75px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Col xs={12} className="platform-section">
          <h2
            className="platform-title"
            style={{
              color: "#0F1931",
              fontSize: "45px",
              maxWidth: "500px",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Frequently Asked Questions
          </h2>
        </Col>
        <Col xs={12} className="platform-section" style={{ marginTop: "25px" }}>
          <AccordionComponent
            items={questionAnswer}
            className="enterprise-accordion"
          />
        </Col>
      </Row>
      {/*
      <Row
        style={{
          backgroundColor: "#FFFFFF",
          paddingBottom: "50px",
          marginTop: "20px",
        }}
        className="justify-content-md-center"
      >
        <h2 className="row-subtitle h-center ">
          Whether it’s your first acquisition or you invest for a living, we’ve
          got the right plan for you.
        </h2>
      </Row>
      <Row
        style={{
          backgroundColor: "#FFFFFF",
          paddingBottom: "110px",
          marginTop: "20px",
        }}
        className="justify-content-md-center"
      >
        <QuestionAnswerTool qas={questionAnswer} />
      </Row>*/}
      <Row
        className="justify-content-center"
        id="mobile-section-sellers"
        style={{ marginTop: "5%", marginRight: "auto" }}
      >
        <Col style={{ paddingRight: "5%", width: "100%", maxWidth: "1000px" }}>
          <Row>
            <h2
              className="home-caption left-align-mobile"
              style={{ fontFamily: "Stix-Two", fontWeight: "bold" }}
            >
              Our
            </h2>
            <h2
              className="home-caption left-align-mobile"
              style={{ fontFamily: "Stix-Two", fontWeight: "bold" }}
            >
              Services
            </h2>
          </Row>
          <Row>
            <p className="home-subcaption left-align-mobile">
              Empower your success
            </p>
            <p
              className="home-paragraph left-align-mobile"
              style={{
                marginTop: "3%",
                width: "100%",
                maxWidth: "550px",
                fontFamily: "Helvetica-Neue-Light, sans-serif",
                color: "#272F53",
                fontSize: "22px",
                lineHeight: "initial",
              }}
            >
              At Ependisis, we offer a comprehensive range of services designed
              to cater to the unique needs of our discerning investors and
              sellers. With our expertise and cutting-edge platform, we ensure
              that you have access to the best deals and opportunities in the
              private equity landscape.
            </p>
          </Row>
        </Col>
        {/* <Col>
          <div>
            {sellersIdeas.map((ideaSet, index) => (
              <SellerIdea
                key={index}
                title={ideaSet.title}
                ideas={ideaSet.ideas}
              />
            ))}
          </div>
        </Col> */}
      </Row>
      <Row
        className="justify-content-center"
        style={{
          marginTop: "3%",
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
          maxWidth: "1100px",
        }}
      >
        {serviceCards.map((card, index) => (
          <Col
            key={index}
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center"
          >
            <PropertyCard
              title={card.title}
              description={card.description}
              variant={card.variant}
            />
          </Col>
        ))}
      </Row>

      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{ marginTop: "1%" }}
      >
        <Footer />
      </Row>
      <Row className="d-block d-sm-none justify-content-center">
        <FooterMobile />
      </Row>
    </Container>
  );
}

export default SellersNew;
