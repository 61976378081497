import { Col, Container, Row } from "react-bootstrap";
import HeaderMenu from "../components/HeaderMenu/HeaderMenu";
import Footer from "../components/Footer/Footer";
import GetStartedHighlight from "../components/GetStartedHighlight/GetStartedHighlight";
import GetStartedHighlightProps from "../interfaces/components/GetStartedHighlight/GetStartedHighlightProps";
import pavlosEconomou from "../assets/pavlosEconomou.jpg";
import theoParperis from "../assets/theoParperis.jpg";
import { FaLinkedin, FaFacebook, FaEnvelope } from "react-icons/fa";
import TeamMemberCard from "../components/TeamMemberCard/TeamMemberCard";
import HeaderMenuMobile from "../components/HeaderMenu/HeaderMenuMobile";
import "./../styles/GetStarted.css";
import { useEffect, useState } from "react";
import FooterMobile from "../components/Footer/FooterMobile";

const propsArray: GetStartedHighlightProps[] = [
  {
    enumeration: "01",
    heading: "Sell Side services",
    paragraph:
      "If you’re navigating the M&A world, our detailed guidance and support under Sell Side services ensure you’re well-prepared. We guide you through every step, ensuring confidence that you’ll maximize the value of your firm. We assist our clients to understand all the sale options comprehensively, following a strategic approach that explains the intricacies of selling a business.",
  },
  {
    enumeration: "02",
    heading: "Raising capital",
    paragraph:
      "With a robust track record in sourcing capital from venture capital, private equity firms, family offices, and institutional investors, our capital raising services are designed to meet your business’s investor needs. Whether you are raising growth capital, management buyouts or launching a new Investment Fund, we provide tailored solutions to address your capital requirements effectively.",
  },
  {
    enumeration: "03",
    heading: "Buy Side services",
    paragraph:
      "Specializing in middle-market M&A, our Buy Side services tap into extensive databases of small- and middle-sized businesses globally, tailored to your criteria. Each deal involves a team of corporate finance experts, blending financial, sectoral, and international expertise.",
  },
  {
    enumeration: "04",
    heading: "Strategic Advisory & Company Valuation",
    paragraph:
      "Our specialists at Ependisis.com assist in creating and implementing strategic plans to propel your business forward. In a dynamic business environment, our Strategic Advisory & Company Valuation services help you navigate changes effectively, bringing the future into focus and elevating your business to the next level.",
  },
];

const GetStarted: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container
      fluid
      style={{
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      {/* Header */}
      <Row
        className="d-none d-sm-flex justify-content-center"
        style={{ backgroundColor: "#0F1931" }}
      >
        <HeaderMenu />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
        style={{
          backgroundColor: "#0F1931",
        }}
      >
        <HeaderMenuMobile />
      </Row>

      {/* Introduction Section */}
      <Row className="justify-content-center text-center mt-4 mb-4">
        <Col md={8}>
          <h2 id="getstarted-main-title">
            Redefining Private <br /> Equity Marketplaces
          </h2>
          <p id="getstarted-main-subtitle">
            “At Ependisis.com our services go beyond being just another online
            marketplace. Here’s what sets us apart.”
          </p>
        </Col>
      </Row>


      {/* Services Section */}
      <Row className="justify-content-center">
        {propsArray.slice(0, 2).map((item, index) => (
          <Col
            key={index}
            md={6}
            className={`d-flex justify-content-center ${isMobile ? "mobile-extra-class-highlight" : ""}`}
            style={{
              minWidth: "400px",
              maxWidth: "400px",
              color: index % 2 === 0 ? "#272F53" : "#FFFFFF",
              backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#0F1931",
              padding: "20px",
              paddingBottom: "40px",
              borderRadius: "10px",
            }}
          >
            <GetStartedHighlight {...item} />
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center">
        {propsArray.slice(2, 4).map((item, index) => (
          <Col
            key={index}
            md={6}
            className={`d-flex justify-content-center ${isMobile ? "mobile-extra-class-highlight" : ""}`}
            style={{
              minWidth: "400px",
              maxWidth: "400px",
              color: !isMobile
                ? index % 2 === 0
                  ? "#FFFFFF"
                  : "#272F53"
                : index % 2 === 0
                  ? "#272F53"
                  : "#FFFFFF",
              backgroundColor: !isMobile
                ? index % 2 === 0
                  ? "#0F1931"
                  : "#FFFFFF"
                : index % 2 === 0
                  ? "#FFFFFF"
                  : "#0F1931",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <GetStartedHighlight {...item} />
          </Col>
        ))}
      </Row>

      {/* New Section */}
      <Row id="aboutus-quote-container">
        <Col id="aboutus-quote-content">
          <h2 id="aboutus-quote-title">
            Boutique Marketplace for Growth Equity and Private Equity Transactions
          </h2>
          <p id="aboutus-quote-description">
            Our platform specialises in facilitating transactions with enterprise
            values ranging from $1M to $100M, offering tailored solutions for all
            stakeholders.
          </p>
          <p id="aboutus-quote-description">
            Join our network of top-tier M&A advisors, investors, and business
            owners.
          </p>
          <a href="mailto:info@ependisis.com">
            <button className="register-button" id="aboutus-quote-button">
              Get in touch
            </button>
          </a>
        </Col>
      </Row>

      <Row className="justify-content-center text-center mt-5">
        <Col xs={8} md={8}>
          <h2 id="meetEpendisisTeam">Meet the Ependisis team</h2>
        </Col>
      </Row>


      <Row className="justify-content-center">
        <TeamMemberCard
          image={theoParperis}
          name="Theo Parperis"
          role="Founder"
          email="tcp@ependisis.com"
          socialLinks={{
            linkedin: "www.linkedin.com/in/theo-parperis-62560741",
            email: "tcp@ependisis.com",
          }}
        />
        <TeamMemberCard
          image={pavlosEconomou}
          name="Pavlos Economou"
          role="CEO"
          email="p.economou@ependisis.com"
          socialLinks={{
            linkedin: "www.linkedin.com/in/pavloseconomou",
            email: "p.economou@ependisis.com",
          }}
        />
      </Row>
      {/* Join Our Team Section */}
      <Row
        className="justify-content-center mt-5 px-3 px-md-5"
        style={{
          backgroundColor: "#161B3B",
          padding: "60px 20px", // Adjust padding for mobile
          minHeight: "250px",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1300px", // Ensures max width for desktop
          display: "flex",
          alignItems: "center",
          color: "#FFFFFF",
          borderRadius: "5px",
        }}
      >
        <Col
          xs={12}
          md={8}
          className="d-flex flex-column align-items-start text-center text-md-start"
          style={{ maxWidth: "600px", marginBottom: "auto" }}
        >
          <h2
            style={{
              fontFamily: '"Helvetica-Neue", sans-serif',
              fontWeight: "bold",
              fontSize: "28px", // Slightly smaller for better mobile scaling
              marginBottom: "20px",
            }}
          >
            Interested in joining our team?
          </h2>
        </Col>
        <Col xs={12} md={4} className="text-center text-md-end" style={{ maxWidth: "600px" }}>
          <p
            style={{
              fontSize: "18px", // Reduce font size for mobile readability
              fontFamily: '"Helvetica-Neue-Light", sans-serif',
              marginBottom: "10px",
            }}
          >
            We are seeking skilled and driven individuals to contribute to
            facilitating successful transactions for buyers and sellers.
          </p>
          <p
            style={{
              fontSize: "18px",
              fontFamily: '"Helvetica-Neue-Light", sans-serif',
              marginBottom: "20px",
            }}
          >
            Submit your CV to{" "}
            <a
              href="mailto:info@ependisis.com"
              style={{
                color: "#FFFFFF",
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              info@ependisis.com
            </a>{" "}
            and take the next step in your career.
          </p>
        </Col>
      </Row>


      {/* Footer */}
      <Row className="d-none d-sm-flex justify-content-center mt-5">
        <Footer />
      </Row>
      <Row
        className="d-block d-sm-none justify-content-center"
      >
        <FooterMobile />
      </Row>
    </Container>
  );
};

export default GetStarted;
